import PropTypes from "prop-types";
import { pulse } from "react-animations";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useConfirm } from "material-ui-confirm";
import LoadingButton from "@mui/lab/LoadingButton";
import styled, { keyframes } from "styled-components";
import { styled as _styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useRef, useState, useContext, useEffect } from "react";
import { IconButton, Box, Card, Grid, Typography, CardActions, CardContent, Collapse, Button } from "@mui/material";

// local
import SpotsLeft from "./SpotsLeft";
import ShareButton from "./ShareButton";
import PassengerList from "./PassengerList";
import AuthContext from "../../contexts/AuthContext";
import AlertContext from "../../contexts/AlertContext";
import LinearProgressBar from "../mobile/LinearProgressBar";
import { addUserToEvent, removeUserFromEvent } from "../../services/users";


// custom expand more icon
const ExpandMore = _styled((props) => {
	const { ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

// custom card animation
const bounceAnimation = keyframes`${pulse}`;
const BounceCard = styled(Card)`
    animation: 1.5s ${bounceAnimation} 0.5s;
`;


export default function EventCard(props) {
	const { mobile, event, setReload, setAddEventModalState,
		defaultExpanded, hideExpand, animate } = props;

	// contexts

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// auth
	const authContext = useContext(AuthContext);
	const { token, user } = authContext;
	const id = user._id;

	// states
	const [loading, setLoading] = useState(false);
	const [expanded, setExpanded] = useState(defaultExpanded || false);

	const confirm = useConfirm();
	const navigate = useNavigate();
	const mountedRef = useRef(true);

	// destructure event
	const { destination, start, date, departure, notes, passengers,
		owner, title, capacity, names } = event;
	const numPassengers = passengers.length;
	const numTotal = capacity;

	const s = start ? start : new Date(date);
	const start_date = s.toDateString();
	const start_time = s.toLocaleTimeString("en-US");
	let joined = passengers.indexOf(id) > -1;


	// effects
	useEffect(() => {
		return () => {
			mountedRef.current = false;
		};
	}, []);

	// functions

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	async function handleJoin() {
		setLoading(true);
		try {
			if (joined) {
				try {
					await confirm({ description: "This action will remove you from this ride!" });
					await removeUserFromEvent({
						userID: id,
						eventID: event._id,
					}, token);
					joined = false;
				} catch (err) {
					if (err) throw err;
				}
			} else {
				await addUserToEvent({
					userID: id,
					eventID: event._id,
				}, token);
				joined = true;
			}
			setReload(true);
		} catch (err) {
			setAlert({ open: true, message: "Error: Something went wrong. Please try again.", severity: "error" });
			console.log(err);
		} finally {
			if (mountedRef.current) setLoading(false);
		}
	}

	function editEvent(event) {
		const { title, destination, start, departure, notes, capacity } = event;
		setAddEventModalState(state => ({
			...state,
			defaultValues: {
				title: title,
				departure: departure,
				destination: destination,
				date: start,
				time: start,
				notes: notes,
				capacity: capacity,
			},
			updateId: event._id,
		}));
		setAddEventModalState(state => ({
			...state,
			open: true,
		}));
	}

	// card sub components
	const desktopInnerCard = (
		<>
			<Grid container direction="row" sx={{
				position: "absolute",
				right: 10,
				top: 10,
				justifyContent: "right",
			}}>
				{id === owner &&
					<Grid item>
						<IconButton
							size="small"
							onClick={() => editEvent(event)}
						>
							<EditIcon />
						</IconButton>
					</Grid>
				}
				<Grid item>
					<ShareButton copy={`${window.location.origin}/event/${event._id}`} size="small" />
				</Grid>
				{!hideExpand &&
					<Grid item>
						<ExpandMore
							disabled={!(departure.address || destination.address || joined)}
							expand={+expanded}
							onClick={handleExpandClick}
						>
							<ExpandMoreIcon size="small" />
						</ExpandMore>
					</Grid>
				}
			</Grid>

			<Grid container sx={{
				direction: "row",
				alignItems: "flex-end",
			}}>
				<Grid item xs={true}>
					<CardContent>
						<Typography variant="h5" color="text.secondary">
							{title}
						</Typography>
						<Typography color="text.secondary">
							On {start_date} @ {start_time}
						</Typography>
						<Typography color="text.secondary">
							Going to {destination.name}
						</Typography>
						<Typography color="text.secondary">
							Pickup from {departure.name}
						</Typography>
					</CardContent>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<CardContent>
							{destination.address &&
								<Typography variant="body2" color="text.secondary">
									<b>Destination Address:</b> {destination.address}
								</Typography>
							}
							{departure.address &&
								<Typography variant="body2" color="text.secondary">
									<b>Pickup Address:</b> {departure.address}
								</Typography>
							}
							{notes && <Typography variant="body2" color="text.secondary">
								<b>Notes:</b> {notes}
							</Typography>
							}
							{names &&
								<PassengerList names={names} passengers={passengers} />
							}
						</CardContent>
					</Collapse>
					<CardActions>
						<LoadingButton
							size="small"
							variant="contained"
							color={joined ? "accent" : "secondary"}
							sx={{
								margin: 1,
							}}
							onClick={handleJoin}
							loading={loading}
						>
							<Typography color="White" sx={{
								fontFamily: "Gudea",
								fontWeight: "bold",
								textTransform: "none",
							}}>
								{joined ? "Leave" : "Join"}
							</Typography>
						</LoadingButton>
					</CardActions>
				</Grid>
				<Grid item>
					<Box sx={{
						height: 80,
						width: 80,
						margin: 2,
					}}>
						<SpotsLeft
							numPassengers={numPassengers}
							numTotal={capacity} />
					</Box>
				</Grid>
			</Grid>
		</>
	);


	return (
		<>
			{mobile ?
				<Card sx={{
					display: "flex",
					alignItems: "end",
					backgroundColor: "rgb(0, 0, 0, 0)",
					boxShadow: "none",
					width: "100%",
					pb: 2,
					px: "1px",
					pr: 1,
				}}>
					<Box sx={{
						backgroundColor: "#ffffff",
						boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
						transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
						borderRadius: 2,
						pl: 2,
						mt: 1,
						width: "100%",
					}}>
						<Grid container direction="row" spacing={2} sx={{
							pb: 1,
						}}>
							<Grid item xs={7}>
								<Grid container direction="column" sx={{
									mt: -1,
								}}>
									<Grid item>
										<Box sx={{
											backgroundColor: "#002D72",
											borderRadius: 2,
											width: "fit-content",
										}}>
											<Typography sx={{
												p: 0.5,
												pl: 2,
												pr: 2,
												color: "White",
												fontSize: 12,
												fontWeight: "bold",
											}}>
												{start.toLocaleTimeString([], { timeStyle: "short" })}
											</Typography>
										</Box>
									</Grid>
									<Grid item>
										<Box sx={{ height: 5 }} />
									</Grid>
									<Grid item sx={{
										py: 0.5,
									}}>
										<Typography sx={{
											fontSize: 18,
											fontWeight: "bold",
										}}>
											{title ? title : `Trip to ${destination}`}
										</Typography>
									</Grid>
									<Grid container direction="row" sx={{
										height: "100%",
										display: "flex",
										alignContent: "center",
									}}>
										<Grid item sx={{
											display: "flex",
											alignContent: "center",
											ml: 0.25,
										}}>
											<Typography>
												{departure.name}
											</Typography>
										</Grid>
										<Grid item>
											<ArrowRightIcon sx={{
												color: "#666666",
												position: "relative",
												top: "-1px",
											}} />
										</Grid>
										<Grid item sx={{
											display: "flex",
											alignContent: "center",
										}}>
											<Typography>
												{destination.name}
											</Typography>
										</Grid>
										<Grid item>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={5}>
								<Grid container spacing={1} direction="column" sx={{
									height: "100%",
									pr: 1,
									mt: 0.5,
									display: "flex",
									justifyItems: "center",
								}}>
									<Grid item xs={4} sx={{
										display: "flex",
										alignItems: "center",
									}}>
										<Grid container spacing={1} direction="row" sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}>
											<Grid item>
												<LinearProgressBar value={Math.floor(numPassengers / numTotal * 100)} />
											</Grid>
											<Grid item>
												<Typography sx={{
													fontSize: 12,
													fontWeight: "bold",
												}}>
													{`${numPassengers} / ${numTotal}`}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={8} sx={{
										height: "50%",
										pb: 3,
									}}>
										<Grid container direction="column">
											<Grid item sx={{
												display: "flex",
												justifyContent: "center",
											}}>
												<Button
													size="small"
													variant="contained"
													color={joined ? "accent" : "secondary"}
													sx={{
														pt: 0.5,
														pb: 0.5,
													}}
													onClick={handleJoin}
												>
													<Typography color="White" sx={{
														fontSize: 12,
														fontWeight: "bold",
														textTransform: "none",
													}}>
														{joined ? "Leave" : "Join"}
													</Typography>
												</Button>
											</Grid>
										</Grid>
										<Grid item sx={{
											mt: 1,
											display: "flex",
											justifyContent: "center",
										}}>
											<IconButton
												sx={{
													pt: 0.5,
													pb: 0.5,
												}}
												onClick={() => navigate(`/event/${event._id}?returnPath=${window.location.pathname.replace("/", "")}`)}
											>
												<MoreHorizIcon />
											</IconButton>
											{id === owner &&
												<IconButton
													size="small"
													onClick={() => editEvent(event)}
													sx={{
														pt: 0.5,
														pb: 0.5,
													}}
												>
													<EditIcon />
												</IconButton>
											}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Card>
				:
				<>
					{animate ?
						<BounceCard sx={{ mr: 2, ml: 2, mb: 2, position: "relative" }} >
							{desktopInnerCard}
						</BounceCard>
						:
						<Card sx={{ mr: 2, ml: 2, mb: 2, position: "relative" }} >
							{desktopInnerCard}
						</Card>
					}
				</>
			}
		</>
	);
}

EventCard.propTypes = {
	mobile: PropTypes.bool.isRequired,
	event: PropTypes.object,
	setReload: PropTypes.func,
	setAddEventModalState: PropTypes.func.isRequired,
	defaultExpanded: PropTypes.bool,
	hideExpand: PropTypes.bool,
	animate: PropTypes.bool,
};
