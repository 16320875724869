import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { IconButton, AppBar, Toolbar, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";


export default function Title(props) {
	const { text, hideBack } = props;

	const navigate = useNavigate();

	return (
		<AppBar color="white" position="fixed">
			<Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
				{hideBack ?
					<IconButton />
					:
					<IconButton onClick={()=>navigate("/chat")}>
						<KeyboardArrowLeftIcon />
					</IconButton>
				}
				<Typography color="inherit" type="title" sx={{
					fontWeight: 700, fontSize: 20,
					position: "absolute",
					left: "50%",
					transform: "translate(-50%, 0)"
				}}>
					{text}
				</Typography>
				<IconButton />
			</Toolbar>
		</AppBar>
	);
}

Title.propTypes = {
	text: PropTypes.string.isRequired,
	hideBack: PropTypes.bool,
};
