import ReactDOM from "react-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ConfirmProvider } from "material-ui-confirm";
import { BrowserRouter as Router } from "react-router-dom";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// local
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


ReactDOM.render(
	<Router>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ConfirmProvider>
				<App />
			</ConfirmProvider>
		</LocalizationProvider>
	</Router>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
