
function login(token, tokenExpirationDate, user, setAuthState) {
	// set auth state
	setAuthState({
		token: token,
		tokenExpirationDate: tokenExpirationDate,
		user: user,
	});

	localStorage.setItem(
		"userData",
		JSON.stringify({
			token: token,
			tokenExpirationDate: tokenExpirationDate && tokenExpirationDate.toISOString(),
			user: user,  // TODO only store token
		})
	);
}

function logout(setAuthState) {
	setAuthState({
		token: null,
		tokenExpirationDate: null,
		user: null,
	});
	localStorage.removeItem("userData");
}

export { login, logout };
