import PropTypes from "prop-types";
import { Paper, Modal } from "@mui/material";

// local
import AccountInfo from "../reusable/AccountInfo";


export default function AccountModal(props) {
	const { open, setOpen, user } = props;


	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
		>
			<Paper sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: 300,
				px: 2,
				py: 3,
			}}>
				<AccountInfo user={user}/>
			</Paper>
		</Modal>
	);
}

AccountModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	user: PropTypes.object,
};
