import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

// local
import { getAllEvents } from "../services/events";
import AuthContext from "../contexts/AuthContext";
import AlertContext from "../contexts/AlertContext";
import EventsContext from "../contexts/EventsContext";
import AddEventModal from "../components/reusable/AddEventModal";
import AddEventButton from "../components/reusable/AddEventButton";


export default function EventsProvider(props) {
	const { mobile } = props;
	// context

	// auth
	const authContext = useContext(AuthContext);
	const { token, user } = authContext;

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// states

	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState(true);

	// manage list of events from database and filtered events
	const [eventState, setEventState] = useState({
		dbEvents: [],
		filteredEvents: [],
	});

	// manage database and local filters
	const [filterState, setFilterState] = useState({
		dbFilters: {},
		localDestinationLocationFilter: null,
		localTimeRangeFilter: [null, null],
		sort: "date",
		descending: true,
	});

	// manage add event modal
	const [addEventModalState, setAddEventModalState] = useState({
		open: false,
		defaultValues: {},
		updateId: null,
	});

	// effects

	// on mount or filter change, get events from database
	useEffect(() => {
		setLoading(true);
		getAllEvents(filterState.dbFilters, token)
			.then((events) => {
				setEventState(state => ({ ...state, dbEvents: events }));
				if (events.length === 0) {
					setAlert({ message: "No events found", open: true, severity: "info" });
				}
			})
			.catch((err) => {
				setAlert({ open: true, message: "Error: Something went wrong. Please try again.", severity: "error" });
				console.log(err);
			})
			.finally(() => setLoading(false));
	}, [filterState.dbFilters, setAlert, reload, token]);

	// prevent multiple reloads after event added
	useEffect(() => {
		if (reload) {
			setReload(false);
		}
	}, [reload]);

	// filter events already fetched from DB
	useEffect(() => {
		const { localDestinationLocationFilter, localTimeRangeFilter } = filterState;
		const startTime = localTimeRangeFilter[0];
		const endTime = localTimeRangeFilter[1];

		let filteredEvents = eventState.dbEvents;
		if (localDestinationLocationFilter) {
			filteredEvents = filteredEvents.filter((event) => {
				const { destination} = event;
				return destination.location && JSON.stringify(destination.location) === JSON.stringify(localDestinationLocationFilter);
			});
		}
		if (startTime && endTime) {
			filteredEvents = filteredEvents.filter((event) => {
				const { start } = event;
				const eventTime = new Date();
				eventTime.setHours(start.getHours());
				eventTime.setMinutes(start.getMinutes());
				const _startTime = new Date();
				_startTime.setHours(startTime.getHours());
				_startTime.setMinutes(startTime.getMinutes());
				const _endTime = new Date(endTime.getTime());
				_endTime.setHours(endTime.getHours());
				_endTime.setMinutes(endTime.getMinutes());
				return eventTime > _startTime && eventTime < _endTime; 
			});
		}

		// filter out events full or locked (i.e. within 24 hours and not joined)
		filteredEvents = filteredEvents.filter((event) => {
			const { passengers, capacity } = event;
			// const date = Date.now()
			return !(
				(passengers.length >= capacity && passengers.indexOf(user._id) === -1) ||  
                // (start - date < HOUR * 24)
                false
			);
		});

		const { sort, descending } = filterState;
		if (sort === "date") {
			filteredEvents.sort((a, b) => a.start - b.start);
		} else if (sort === "passengers") {
			filteredEvents.sort((a, b) => a.passengers.length - b.passengers.length);
		}

		if (!descending) {
			filteredEvents.reverse();
		}

		setEventState(state => ({ ...state, filteredEvents: filteredEvents }));
	}, 
	[
		eventState.dbEvents, 
		filterState,
		user
	]);

	return (
		<EventsContext.Provider value={{
			events: eventState.filteredEvents,
			loading: loading,
			setFilterState: setFilterState,
			setReload: setReload,
			localDestinationLocationFilter: filterState.localDestinationLocationFilter,
			setAddEventModalState: setAddEventModalState,
			filterState: filterState,
		}}>
			<AddEventModal 
				mobile={mobile}
				open={addEventModalState.open} 
				setOpen={(open) => setAddEventModalState(state => ({
					...state,
					open: open
				}))} 
				loading={loading}
				setReload={setReload}
				defaultValues={addEventModalState.defaultValues}
				updateId={addEventModalState.updateId}
			/>
			<AddEventButton 
				mobile={mobile}
				setOpen={(open) => setAddEventModalState(state => ({
					...state,
					open: open
				}))} 
				resetAddEventModal={() => setAddEventModalState(state => ({
					...state,
					defaultValues: {},
					updateId: null,
				}))}
			/>

			{/* Card or Calendar View */}
			<Outlet context={{ setReload }}/>
		</EventsContext.Provider>
	);
}

export function useSetReload() {
	return useOutletContext();
}

EventsProvider.propTypes = {
	mobile: PropTypes.bool.isRequired,
};
