import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import MoreIcon from "@mui/icons-material/FilterAlt";
import { IconButton, CssBaseline, SwipeableDrawer } from "@mui/material";

// local
import FilterDrawer from "./FilterDrawer";

// constants
const DRAWER_BLEEDING = 56;

// custom div styling
const Root = styled("div")(() => ({
	height: "100%",
}));


export default function SwipeableEdgeDrawer(props) {
	const { open, setOpen, filterState, setFilterState, setFilters } = props;

	// functions
	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};


	return (
		<Root>
			<CssBaseline />
			<Global
				styles={{
					".MuiDrawer-root > .MuiPaper-root": {
						height: `calc(90% - ${DRAWER_BLEEDING}px)`,
						overflow: "visible",
					},
				}}
			/>

			<IconButton
				size="large"
				aria-label="show more"
				aria-haspopup="true"
				onClick={toggleDrawer(true)}
				color="inherit"
				cursor="pointer"
				sx={{ backgroundColor: "transparent" }}
			>
				<MoreIcon />
			</IconButton>

			<SwipeableDrawer
				anchor="bottom"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				swipeAreaWidth={DRAWER_BLEEDING}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<FilterDrawer
					toggleDrawer={toggleDrawer}
					open={open}
					filterState={filterState}
					setFilterState={setFilterState}
					setFilters={setFilters}
				/>
			</SwipeableDrawer>
		</Root>
	);
}

SwipeableEdgeDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	filterState: PropTypes.object.isRequired,
	setFilters: PropTypes.func.isRequired,
	setFilterState: PropTypes.func.isRequired,
};
