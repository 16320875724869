import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import FitText from "@plutonium-js/react-fit-text";


export default function MetricBox(props) {
	const { title, content } = props;


	return (
		<Grid container direction="column" sx={{
			height: 100,
			width: 150,
			background: "#f3f3f3",
			position: "relative",
			m: 2,
			display: "flex",
		}}>
			<Grid item sx={{
				pt: 1,
			}}>
				<Typography sx={{
					width: "100%",
					textAlign: "center",
					fontSize: "75%",
				}}>
					{title}
				</Typography>
			</Grid>
			<Grid item xs={true} sx={{
				p: 1,
				width: "100%",
				position: "relative",
				top: "50%",
				transform: "translateY(-50%)"
			}}>
				<FitText maxSize={25} style={{
					height: "100%",
					width: "100%",
					textAlign: "center"
				}}>
					{content}
				</FitText>
			</Grid>
		</Grid>
	);
}

MetricBox.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
};
