import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Toolbar, CircularProgress } from "@mui/material";

// local
import AuthContext from "../contexts/AuthContext";
import { getEvent } from "../services/events";
import BackButton from "../components/reusable/BackButton";
import ShareButton from "../components/reusable/ShareButton";
import EventComponent from "../components/reusable/EventComponent";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;


function EventView(props) {
	const { mobile, eventId } = props;

	let { id } = useParams();
	id = id || eventId;

	const authContext = useContext(AuthContext);

	const { user, token } = authContext;

	const navigate = useNavigate();
	const { search, pathname } = useLocation();
	const params = new URLSearchParams(search);

	const [event, setEvent] = useState(null);
	const [reload, setReload] = useState(true);

	const [returnPath, setReturnPath] = useState("");

	if (!returnPath && params.has("returnPath")) {
		setReturnPath(params.get("returnPath"));
	}

	useEffect(() => {
		if (!mobile && pathname.indexOf("event") > -1) {
			navigate(`/card#${id}`);
			return;
		}
		if (reload) {
			getEvent(id, token)
				.then((event) => {
					setEvent(event);
				})
				.catch((err) => {
					console.log(err);
				});
			setReload(false);
		}
	}, [id, token, user._id, mobile, navigate, pathname, reload]);


	return (
		<>
			{!event ?
				<Box sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
					<CircularProgress color="primary" />
				</Box>
				:
				<>
					<BackButton path={returnPath ? "/" + returnPath : "/card"} />
					<ShareButton copy={window.location.href} sx={{
						zIndex: 3000, position: "fixed", top: 20, right: 25, boxShadow: 3, bgcolor: "#FFFFFF", color: "black"
					}} />
					<Toolbar />
					<EventComponent event={event} setReload={setReload} mobile={true} />
				</>
			}
		</>
	);
}

export default EventView;

EventView.propTypes = {
	mobile: PropTypes.bool.isRequired,
	eventId: PropTypes.any
};
