// import { Pie } from "react-chartjs-2";
// import { Line } from "react-chartjs-2";
import { Button, Grid } from "@mui/material";
import { useState, useContext, useEffect } from "react";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from "chart.js";

// local
import MetricBox from "../components/desktop/MetricBox";
import Header from "../components/desktop/Header";
import MetricList from "../components/desktop/MetricList";
import SelectList from "../components/desktop/SelectList";
import AuthContext from "../contexts/AuthContext";
import { getAllUsers } from "../services/users";
import { getAllEvents } from "../services/events";


ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
);


function MetricView() {
	// auth
	const authContext = useContext(AuthContext);
	const { token } = authContext;

	// states

	const [users, setUsers] = useState(null);
	const [events, setEvents] = useState(null);
	const [selected, setSelected] = useState(0);
	const [display, setDisplay] = useState("events");
	const [metrics, setMetrics] = useState([]);

	const [displayContent, setDisplayContent] = useState({
		"pane": <></>,
		"top": <></>,
		"bottom": <></>,
	});

	// effects

	useEffect(() => {
		getAllUsers(token)
			.then((res) => {
				// console.log(res.data.data[0])
				setUsers(res.data.data);
			});
		getAllEvents({}, token)
			.then((res) => {
				console.log(res[0]);
				setEvents(res);
			});
	}, [token]);

	// compute metrics
	useEffect(() => {
		if (users && events) {
			if (display === "overall") {
				const numUsers = users.length;
				const numSessions = users.reduce((total, { meta }) => total + meta.sessions, 0);
				const numNoPay = users.reduce((total, { meta }) => total + meta.numNoPay, 0);
				const numRidesBooked = users.reduce((total, { meta }) => total + meta.numRidesBooked, 0);
				const numRidesCancelled = users.reduce((total, { meta }) => total + meta.numRidesCancelled, 0);
				const numRatings = users.reduce((total, { meta }) => total + meta.ratings.length, 0);

				setMetrics([
					<MetricBox key={0} title={"users"} content={numUsers} />,
					<MetricBox key={1} title={"sessions"} content={numSessions} />,
					<MetricBox key={2} title={"no pays"} content={numNoPay} />,
					<MetricBox key={3} title={"rides booked"} content={numRidesBooked} />,
					<MetricBox key={4} title={"rides cancelled"} content={numRidesCancelled} />,
					<MetricBox key={5} title={"ratings"} content={numRatings} />,
				]);
			} else if (display === "users") {
				const user = users[selected];
				const meta = user.meta;

				setMetrics([
					<MetricBox key={0} title={"sessions"} content={meta.sessions} />,
					<MetricBox key={1} title={"no pays"} content={meta.numNoPay} />,
					<MetricBox key={2} title={"rides booked"} content={meta.numRidesBooked} />,
					<MetricBox key={3} title={"rides cancelled"} content={meta.numRidesCancelled} />,
					<MetricBox key={4} title={"ratings"} content={meta.ratings.length} />,
				]);
			} else if (display === "events") {
				const event = events[selected];

				setMetrics([
					<MetricBox key={0} title={"capacity"} content={event.capacity} />,
					<MetricBox key={1} title={"passengers"} content={event.passengers.length} />,
					<MetricBox key={2} title={"departure"} content={JSON.stringify(event.departure)} />,
				]);
			}
		}
	}, [display, selected, users, events, token]);

	// display metrics
	useEffect(() => {
		if (users && events) {
			if (display === "overall") {
				setDisplayContent(state => ({
					...state,
					"pane":
                        <MetricList metrics={metrics} />,
					"top":
                        <>
                            No Session Data by Month {/* <Line data={lineData} options={options}/> */}
                        </>,
					"bottom":
                        <>
                            No Session Data by Device {/* <Pie data={pieData} options={options}/> */}
                        </>,
				}));
			} else if (display === "users") {
				setDisplayContent(state => ({
					...state,
					"pane":
						<SelectList
							field={"username"}
							items={users}
							selected={selected}
							setSelected={setSelected}
						/>,
					"top":
                        <>
                            No Session Data by Month {/* <Line data={lineData} options={options}/> */}
                        </>,
					"bottom":
                        <MetricList metrics={metrics} />,
				}));
			} else if (display === "events") {
				setDisplayContent(state => ({
					...state,
					"pane":
						<SelectList
							field={"title"}
							items={events}
							selected={selected}
							setSelected={setSelected}
						/>,
					"top":
                        <>
                            No Session Data by Month {/* <Line data={lineData} options={options}/> */}
                        </>,
					"bottom":
                        <MetricList metrics={metrics} />,
				}));
			}
		}
	}, [display, selected, users, events, metrics]);


	// TODO implement (but don't delete)
	// const options = {
	//     responsive: true,
	//     maintainAspectRatio: false,
	//     plugins: {
	//         legend: {
	//             position: "top",
	//         },
	//     },
	// };
	// const labels = ["January", "February", "March", "April", "May", "June", "July"];
	// const lineData = {
	//     labels,
	//     datasets: [
	//         {
	//             label: "Sessions",
	//             data: [-43, 667, 803, 900, 363, -743, 257],
	//             borderColor: "rgb(255, 99, 132)",
	//             backgroundColor: "rgba(255, 99, 132, 0.5)",
	//         },
	//     ],
	// };
	// const pieData = {
	//     labels: ["Red", "Blue"],
	//     datasets: [
	//         {
	//             label: "# of Votes",
	//             data: [12, 19],
	//             backgroundColor: [
	//                 "rgba(255, 99, 132, 0.2)",
	//                 "rgba(54, 162, 235, 0.2)",
	//             ],
	//             borderColor: [
	//                 "rgba(255, 99, 132, 1)",
	//                 "rgba(54, 162, 235, 1)",
	//             ],
	//             borderWidth: 1,
	//         },
	//     ],
	// };


	return (
		<>
			<Header />

			<Grid container direction="row" sx={{
				height: "calc(100vh - 85px)",
				position: "relative",
			}}>
				<Grid item xs={4} sx={{
					borderRightStyle: "solid",
					borderWidth: 1,
					borderColor: "#ADADAD",
					height: "100%",
				}}>
					<Grid container direction="column" sx={{
						height: "100%",
					}}>
						<Grid item xs={1} sx={{
							borderBottomStyle: "solid",
							borderWidth: 1,
							borderColor: "#ADADAD",
							width: "100%",
						}}>
							<Grid container direction="row" sx={{
								height: "100%",
								alignItems: "center",
								gap: 2,
								px: 2,
							}}>
								<Grid item>
									<Button variant="outlined" onClick={() => setDisplay("overall")}>
                                        Overall
									</Button>
								</Grid>
								<Grid item>
									<Button variant="outlined" onClick={() => setDisplay("users")}>
                                        Users
									</Button>
								</Grid>
								<Grid item>
									<Button variant="outlined" onClick={() => setDisplay("events")}>
                                        Events
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={11} sx={{
							width: "100%",
						}}>
							{displayContent.pane}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={8} sx={{
					height: "100%",
				}}>
					<Grid container direction="column" sx={{
						height: "100%",
					}}>
						<Grid item xs={1} sx={{
							borderBottomStyle: "solid",
							borderWidth: 1,
							borderColor: "#ADADAD",
						}}>
						</Grid>
						<Grid item xs={6} sx={{
							borderBottomStyle: "solid",
							borderWidth: 1,
							borderColor: "#ADADAD",
							p: 3,
							overflow: "scroll",
							width: "100%",
						}}>
							{displayContent.top}
						</Grid>
						<Grid item xs={5} sx={{
							width: "100%",
							p: 3,
						}}>
							{displayContent.bottom}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default MetricView;
