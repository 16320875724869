
const areDatesOnSameDay = (a, b) =>
	a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate();

const convertDay = { 0: "Sun", 1: "Mon", 2: "Tue", 3: "Wed", 4: "Thu", 5: "Fri", 6: "Sat" };
const convertMonth = { 0: "Jan", 1: "Feb", 2: "Mar", 3: "April", 4: "May", 5: "June", 6: "July", 7: "Aug", 8: "Sep", 9: "Oct", 10: "Nov", 11: "Dec" };

export { areDatesOnSameDay, convertDay, convertMonth };
