import moment from "moment";
import { TimePicker } from "antd";
import PropTypes from "prop-types";
import DatePicker from "@mui/lab/DatePicker";
import Clear from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import PeopleIcon from "@mui/icons-material/People";
import { useEffect, useState, useContext } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { Paper, Avatar, useTheme, Box, Grid, Modal, Button, Tooltip, TextField, Typography } from "@mui/material";

// local
import Add from "../mobile/Add";
import AuthContext from "../../contexts/AuthContext";
import PlacesAutocomplete from "./PlacesAutocomplete";
import AlertContext from "../../contexts/AlertContext";
import { createEvent, editEvent } from "../../services/events";

// styles
import "../../styles/time-picker.css";
import "antd/dist/antd.min.css";

// constants
const MIN_CAPACITY = 2;
const MAX_CAPACITY = 8;
const MAX_NOTES_LEN = 249;
const TIME_FORMAT = "HH:mm";
// const TOMORROW = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
const NOW = new Date();
const NEXT_YEAR_END = new Date(new Date().getFullYear() + 2, 0, 0, 0, 0, 0, 0);


export default function AddEventModal(props) {
	const { open, setOpen, setReload, defaultValues, updateId, mobile } = props;
	const { title, departure, destination, date, time, notes, capacity } = defaultValues || {};

	const theme = useTheme();
	const { mode } = theme.palette;

	// auth
	const authContext = useContext(AuthContext);
	const { user, token } = authContext;

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// states

	const [loading, setLoading] = useState(false);

	// manage event details
	const [eventDetails, setEventDetails] = useState({
		departure: { value: null },
		destination: { value: null },
		date: null,
		time: null,
	});

	const [errorState, setErrorState] = useState({
		capacity: false,
		notes: false,
	});

	useEffect(() => {
		const capacityError = (() => {
			if (eventDetails.capacity) {
				try {
					// no fractions
					if (typeof eventDetails.capacity === "string" && eventDetails.capacity.indexOf(".") > -1) {
						return true;
					}
					
					const val = parseInt(eventDetails.capacity);
					return !(val >= MIN_CAPACITY && val <= MAX_CAPACITY);
				} catch (err) {
					// empty body
				}
				return true;
			}
			return false;
		})();
		const notesError = eventDetails.notes && eventDetails.notes.length > MAX_NOTES_LEN;
		setErrorState(state => ({ ...state, capacity: capacityError, notes: notesError }));
	}, [
		eventDetails.capacity,
		eventDetails.notes,
	]);

	useEffect(() => {
		setEventDetails({
			title: title,
			departure: {
				name: departure && departure.name,
				value: departure && departure.address && {
					place_name: departure.address,
					center: departure.location,
				}
			},
			destination: {
				name: destination && destination.name,
				value: destination && destination.address && {
					place_name: destination.address,
					center: destination.location,
				}
			},
			date: date,
			time: time,
			notes: notes,
			capacity: capacity,
		});
		setErrorState({
			capacity: false,
			notes: false,
		});
	}, [open, date, departure, destination, notes, time, title, capacity]);

	// functions

	function validateEvent(departure, destination, date, time, capacity) {
		if (!departure.name) {
			throw new Error("Please provide a pickup location!");
		}

		if (!destination.name) {
			throw new Error("Please provide a destination!");
		}

		if (!capacity) {
			throw new Error("Please provide a capacity!");
		}

		if (!date) {
			throw new Error("Please provide a date!");
		}

		if (!time) {
			throw new Error("Please provide a time!");
		}

		return;
	}

	// add event to database
	async function handleCreate() {
		setLoading(true);
		try {
			let { title, departure, destination, date, time, notes, capacity } = eventDetails;

			validateEvent(departure, destination, date, time, capacity);

			const _departure = {
				name: departure.name,
				...(departure.value && {
					address: departure.value.place_name,
					location: departure.value.center,
				}),
			};
			const _destination = {
				name: destination.name,
				...(destination.value && {
					address: destination.value.place_name,
					location: destination.value.center,
				}),
			};

			date = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				time.getHours(),
				time.getMinutes(),
				0,
			);

			const now = new Date();
			if (date < now) {
				throw new Error("Events must created in the future!");
			}

			const event = {
				title: title,
				departure: _departure,
				destination: _destination,
				date: date,
				owner: user._id,
				notes: notes,
				capacity: capacity,
			};
			await createEvent(event, token);

			// on success
			setAlert({ message: "Event Added!", open: true, severity: "success" });
			setEventDetails({
				departure: { value: null },
				destination: { value: null },
				date: null,
				time: null,
			});
			setOpen(false);
			setReload(true);
		} catch (error) {
			setAlert({ message: error.message, open: true, severity: "error" });
			console.log(error);
		}
		setLoading(false);
	}

	async function handleEdit() {
		setLoading(true);
		try {
			let { title, departure, destination, date, time, notes, capacity } = eventDetails;

			validateEvent(departure, destination, date, time, capacity);

			const _departure = {
				name: departure.name,
				...(departure.value && {
					address: departure.value.place_name,
					location: departure.value.center,
				}),
			};
			const _destination = {
				name: destination.name,
				...(destination.value && {
					address: destination.value.place_name,
					location: destination.value.center,
				}),
			};

			date = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				time.getHours(),
				time.getMinutes(),
				0,
			);

			const now = new Date();
			if (date < now) {
				throw new Error("Events must created in the future!");
			}

			const event = {
				title: title,
				departure: _departure,
				destination: _destination,
				date: date,
				owner: user._id,
				notes: notes,
				capacity: capacity,
			};

			const params = {
				userID: user._id,
				eventID: updateId,
				newEvent: event,
			};
			await editEvent(params, token);

			// on success
			setAlert({ message: "Event Updated!", open: true, severity: "success" });
			setEventDetails({
				departure: { value: null },
				destination: { value: null },
				date: null,
				time: null,
			});
			setOpen(false);
			setReload(true);
		} catch (error) {
			setAlert({ message: error.message, open: true, severity: "error" });
			console.log(error);
		}
		setLoading(false);
	}


	return (
		<>
			{mobile ?
				<Modal
					open={open}
					onClose={() => setOpen(false)}
					sx={{
						overflowY: "scroll",
						display: "flex",
						alignItems: "safe center",
						justifyContent: "center",
					}}
				>
					<Grid
						container
						spacing={0}
						direction="column"
						overlow="hidden"
						sx={{
							backgroundColor: "#73BFB8",
							height: "100vh",
							overflow: "auto"
						}}
					>
						<Grid item={true} xs={1.3}>
							<Avatar variant="contained"
								onClick={() => setOpen(false)}
								sx={{
									position: "absolute",
									shape: "circular",
									left: "6%",
									top: "4%",
									borderRadius: 40,
									backgroundColor: "#B9DFDB"
								}}
							>
								<Clear/>
							</Avatar>
						</Grid>
						<Grid item={true} display="flex" xs={1} sx={{ alignItems: "center", justifyContent: "flex-start" }}>
							<Typography variant="h3" sx={{ fontSize: 30, fontWeight: 620, ml: 3, color: "white" }}>
								<Box sx={{ textAlign: "left" }}>Add Trip</Box>
							</Typography>
						</Grid>
						<Grid item={true} xs={9.7} sx={{
							maxHeight: 9.7 / 12, backgroundColor: "#FFFFFF",
							borderRadius: "35px 35px 0px 0px", scrollPaddingTop: 20
						}} overflow="scroll">
							<Box sx={{ pb: 5 }} overflow="scroll">
								<Add 
									eventDetails={eventDetails} 
									setEventDetails={setEventDetails} 
									mobile={mobile}
									errorState={errorState}
								/>
							</Box>

							<LoadingButton
								size="small"
								variant="contained"
								color="secondary"
								onClick={() => {
									if (Object.values(errorState).every(val => !val)) {
										updateId ? handleEdit() : handleCreate();
									} else {
										setAlert({ message: "Please provide valid event details", open: true, severity: "error" });
									}
								}}
								loading={loading}
								sx={{
									position: "absolute",
									height: 45,
									width: 100,
									bottom: 20,
									right: 15,
									backgroundColor: "orange.thick",
									borderRadius: 15
								}}>
								<Typography color="White" sx={{
									fontFamily: "Gudea",
									fontWeight: "bold",
									textTransform: "none",
								}}>
									{updateId ? "Update" : "Create"}
								</Typography>
							</LoadingButton>
						</Grid>
					</Grid>
				</Modal>
				:
				<Modal
					open={open}
					onClose={() => setOpen(false)}
					sx={{
						overflowY: "scroll",
						display: "flex",
						alignItems: "safe center",
						justifyContent: "center",
					}}
				>
					<Box
						container
						sx={{
							width: 400,
						}}
					>
						<Paper>
							{/* Vertical Grid */}
							<Grid container spacing={2} sx={{
								px: 4,
								py: 2,
							}}>
								{/* Event Title */}
								<Grid item sx={{
									width: "90%",
								}}>
									<TextField
										label="Event Title"
										variant="standard"
										sx={{ width: "100%" }}
										value={eventDetails.title || ""}
										onChange={(event) => {
											setEventDetails({ ...eventDetails, title: event.target.value });
										}}
									/>
								</Grid>
								{/* Spacer */}
								<Grid item sx={{
									width: "100%",
								}}>
									<Box />
								</Grid>
								{/* Pickup */}
								<Grid item sx={{
									width: "90%",
								}}>
									<Grid container spacing={2} sx={{
										alignItems: "center"
									}}>
										<Grid item>
											<LocationOnIcon fontSize="large" sx={{
												color: "#757575"
											}} />
										</Grid>
										<Grid item xs={true}>
											<PlacesAutocomplete
												label="Pickup"
												value={eventDetails.departure.value || null}
												setValue={(dict) => {
													const { departure } = eventDetails;
													setEventDetails({
														...eventDetails, departure: {
															...departure,
															name: dict && dict.text,
															value: dict,
														}
													});
												}}
												inputValue={eventDetails.departure.name || ""}
												setInputValue={(value) => {
													const { departure } = eventDetails;
													setEventDetails({
														...eventDetails, departure: {
															...departure,
															name: value,
															value: null,
														}
													});
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								{/* Destination */}
								<Grid item sx={{
									width: "90%",
								}}>
									<Grid container spacing={2} sx={{
										alignItems: "center"
									}}>
										<Grid item>
											<SportsScoreIcon fontSize="large" sx={{
												color: "#757575",
											}} />
										</Grid>
										<Grid item xs={true}>
											<PlacesAutocomplete
												label="Destination"
												value={eventDetails.destination.value || null}
												setValue={(dict) => {
													const { destination } = eventDetails;
													setEventDetails({
														...eventDetails, destination: {
															...destination,
															name: dict && dict.text,
															value: dict,
														}
													});
												}}
												inputValue={eventDetails.destination.name || ""}
												setInputValue={(value) => {
													const { destination } = eventDetails;
													setEventDetails({
														...eventDetails, destination: {
															...destination,
															name: value,
															value: null,
														}
													});
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								{/* Capacity */}
								<Grid item sx={{
									width: "90%",
								}}>
									<Grid container spacing={2} sx={{
										alignItems: "center"
									}}>
										<Grid item>
											<PeopleIcon fontSize="large" sx={{
												color: "#757575",
											}} />
										</Grid>
										<Grid item xs={true}>
											<Tooltip title={errorState.capacity ?
												"Capacity must be between 2 and 8"
												:
												""}
											>
												<TextField
													sx={{ width: "100%" }}
													error={!!errorState.capacity}
													label="Capacity"
													value={eventDetails.capacity || ""}
													onChange={(event) => setEventDetails(state => ({
														...state,
														capacity: event.target.value,
													}))}
													// InputProps={{
													// 	"type": "number",
													// }}
												/>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
								{/* Spacer */}
								<Grid item sx={{
									width: "100%",
								}}>
									<Box />
								</Grid>

								<Grid item sx={{ width: "80%" }}>
									<DatePicker
										minDate={NOW}
										maxDate={NEXT_YEAR_END}
										label="Date"
										openTo="day"
										views={["year", "month", "day"]}
										value={eventDetails.date || null}
										onChange={(value) => { setEventDetails({ ...eventDetails, date: value }); }}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Grid>

								{/* Time */}
								<Grid item sx={{ width: "78.5%" }}>
									<TimePicker
										className="timepicker-styles"
										size="large"
										minuteStep={5}
										format={TIME_FORMAT}
										placeholder="Time"
										style={{ 
											width: "100%", 
											height: 56, 
											fontSize: 20, 
											borderRadius: "4px",
											background: "rgb(0,0,0,0)"
										}}
										getPopupContainer={triggerNode => triggerNode.parentNode}
										value={eventDetails.time ? moment(eventDetails.time) : undefined}
										onChange={(value) => { setEventDetails({ ...eventDetails, time: value && value._d }); }}
									/>
								</Grid>

								{/* Notes */}
								<Grid item sx={{
									width: "90%",
								}}>
									<Tooltip title={errorState.notes ?
										"Notes must be less than 250 characters"
										:
										""
									}
									>
										<TextField
											error={!!errorState.notes}
											id="standard-textarea"
											label="Notes"
											multiline
											variant="standard"
											size="small"
											value={eventDetails.notes || ""}
											onChange={(event) => setEventDetails({ ...eventDetails, notes: event.target.value })}
											sx={{
												width: "100%",
											}}
										/>
									</Tooltip>
								</Grid>
								{/* Buttons */}
								<Grid item container sx={{
									width: "100%",
									justifyContent: "end"
								}}>
									<Button
										size="small"
										variant="contained"
										color={mode === "light" ? "text" : "action"}
										onClick={() => setOpen(false)}
										sx={{
											margin: 1,
										}}>
										<Typography sx={{
											fontFamily: "Gudea",
											fontWeight: "bold",
											textTransform: "none",
										}}>
                                            Cancel
										</Typography>
									</Button>
									<LoadingButton
										size="small"
										variant="contained"
										color="secondary"
										onClick={() => {
											if (Object.values(errorState).every(val => !val)) {
												updateId ? handleEdit() : handleCreate();
											} else {
												setAlert({ message: "Please provide valid event details", open: true, severity: "error" });
											}
										}}
										loading={loading}
										sx={{
											margin: 1,
										}}>
										<Typography color="White" sx={{
											fontFamily: "Gudea",
											fontWeight: "bold",
											textTransform: "none",
										}}>
											{updateId ? "Update" : "Create"}
										</Typography>
									</LoadingButton>
								</Grid>
							</Grid>
						</Paper>
					</Box>
				</Modal>
			}
		</>
	);
}

AddEventModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	setReload: PropTypes.func.isRequired,
	defaultValues: PropTypes.object,
	updateId: PropTypes.string,
	mobile: PropTypes.bool.isRequired,
};
