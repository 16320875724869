import PropTypes from "prop-types";
import { Tooltip, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";


export default function AddEventButton(props) {
	const { mobile, setOpen, resetAddEventModal } = props;

	return (
		<>
			{ mobile ?
				<IconButton 
					variant="contained"
					onClick={() => {
						setOpen(true);
						if (resetAddEventModal) resetAddEventModal();
					}}
					sx={{
						position: "absolute",
						height: 45,
						width: 45,
						bottom: 85,
						right: 20,
						zIndex: 3,
						borderRadius: 100,
						backgroundColor: "#ffffff",
					}}
				>
					<AddCircleIcon sx={{
						height: 55,
						width: 55,
						color: "primary.light",
					}}/>
				</IconButton>
				:
				<Tooltip title="Add Event">
					<IconButton 
						onClick={() => {
							setOpen(true);
							if (resetAddEventModal) resetAddEventModal();
						}}
						sx={{
							position: "absolute",
							height: 55,
							width: 55,
							bottom: 15,
							left: 15,
							zIndex: 3,
							borderRadius: 100,
						}}
					>
						<AddCircleIcon sx={{
							height: 55,
							width: 55,
							color: "primary.light",
						}}/>
					</IconButton>
				</Tooltip>
			}     
		</>
	);
}

AddEventButton.propTypes = {
	mobile: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	resetAddEventModal: PropTypes.func.isRequired,
};
