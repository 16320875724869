import PropTypes from "prop-types";
import { Virtuoso } from "react-virtuoso";
import { useRef, isValidElement } from "react";
import { ListItem, Card, Typography } from "@mui/material";

// local
import EventCard from "../reusable/EventCard";
import { convertDay, convertMonth } from "../../utils/date";


export default function CardList(props) {
	const { events, setReload, hideTitle, setAddEventModalState } = props;

	// hooks
	const virtuoso = useRef(null);

	// define display component
	let display;
	if (hideTitle) {
		display = events;
	} else {
		display = [ 
			<ListItem key={0} sx={{
				display:"flex", 
				flexDirection:"column", 
				alignItems:"flex-start", 
				justifyContent:"flex-start",
				my: -1,
			}}> 
				<Card 
					elevation={0} 
					position="relative"
					sx={{
						backgroundColor: "transparent", 
						width: 1, 
						height: 75, 
						borderRadius: 2
					}}
				>
					<Typography sx={{
						fontSize: 14, 
						fontWeight: 1000 
					}}>
                        UPCOMING
					</Typography>
					<Typography sx={{
						fontSize: 30, 
						fontWeight: "bold" 
					}}>
                        Trips
					</Typography>
				</Card>
			</ListItem>,
			...events 
		];
	}

	// get date string from event
	function getDateString(event) {
		const start_year = event.start.getFullYear();
		const start_month = convertMonth[event.start.getMonth()];
		const start_date_m = event.start.getDate();
		const start_day = convertDay[event.start.getDay()];
		return `${start_day}, ${start_month} ${start_date_m}, ${start_year}`;
	}


	return (
		<>
			<Virtuoso
				ref={virtuoso}
				style={{ height: "100%" }}
				data={display}
				itemContent={(_index, content) => (
					isValidElement(content) ? 
						content
						:
						<>
							<Typography sx={{
								pb: 1,
								pl: 1,
								color: "#ffffff",
								fontWeight: "bold",
							}}>
								{getDateString(content)}
							</Typography>
							<EventCard 
								mobile={true}
								event={content}
								setReload={setReload}
								setAddEventModalState={setAddEventModalState}
							/>
						</>
				)}
			/>
		</>
	);
}

CardList.propTypes = {
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	setReload: PropTypes.func.isRequired,
	hideTitle: PropTypes.bool,
	setAddEventModalState: PropTypes.func.isRequired,
};
