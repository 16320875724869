import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@mui/material";


export default function LandingPageContent(props) {
	const { image, text1, text2, text3 } = props;


	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Box
							component="img"
							sx={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
							pt={0}
							src={image}
						/>
					</Grid>
					<Grid item md={6} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", px: 2 }}>
							<Typography variant="h5">
								{text1}
							</Typography>
							<Typography variant="h3" pt={1.5} pb={2} sx={{ fontWeight: "bold", wordWrap: "break-word", lineHeight: 1.1, fontFamily: "Montserrat" }}>
								{text2}
							</Typography>
							<Typography variant="h5" sx={{ fontWeight: 300, wordWrap: "break-word", lineHeight: 1.2 }}>
								{text3}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

LandingPageContent.propTypes = {
	image: PropTypes.any.isRequired,
	text1: PropTypes.string.isRequired,
	text2: PropTypes.string.isRequired,
	text3: PropTypes.string.isRequired,
	padding: PropTypes.number.isRequired,
};
