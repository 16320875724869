import mapboxgl from "!mapbox-gl";
import PropTypes from "prop-types";
import "react-resizable/css/styles.css";
import { Virtuoso } from "react-virtuoso";
import { Resizable } from "react-resizable";
import { AutoSizer } from "react-virtualized";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import { Paper, Box, Grid, CircularProgress } from "@mui/material";

// local
import Map from "../components/desktop/Map";
import Header from "../components/desktop/Header";
import useWindowDimensions from "../utils/window";
import CardList from "../components/mobile/CardList";
import EventsContext from "../contexts/EventsContext";
import EventCard from "../components/reusable/EventCard";
import ViewToggle from "../components/desktop/ViewToggle";
import SortEvents from "../components/reusable/SortEvents";
import FiltersMenu from "../components/desktop/FiltersMenu";
import MobileAppBar from "../components/mobile/MobileAppBar";
import MobileAppBarBottom from "../components/mobile/MobileAppBarBottom";

// services
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

// constants
const minSizes = [0.2, 0.3, 0.2];


function CardView(props) {
	const { mobile } = props;

	// events context
	const eventsContext = useContext(EventsContext);
	const { events, loading, setFilterState, setReload,
		localDestinationLocationFilter, setAddEventModalState, filterState } = eventsContext;

	// hooks
	const navigate = useNavigate();
	const { width } = useWindowDimensions();

	// manage position of columns 
	const [prevWidth, setPrevWidth] = useState(width);
	const [columnDivs, setColumnDivs] = useState([width * 0.3, width * 0.7]);

	// manage virtuso
	const { hash } = useLocation();
	const virtuoso = useRef(null);

	let index;
	if (hash) {
		const id_list = events.map(({ _id }) => _id);
		index = id_list.indexOf(hash.slice(1));
	}

	// manage width change
	useEffect(() => {
		const dif = width - prevWidth;
		setColumnDivs(columnDivs => {
			let temp = [...columnDivs];
			temp[0] = temp[0] + dif / 2;
			temp[1] = temp[1] + dif / 2;
			if (temp[0] < minSizes[0] * width ||
        temp[1] - temp[0] < minSizes[1] * width ||
        temp[2] - temp[1] < minSizes[2] * width) {
				temp = [width * 0.3, width * 0.7];
			}
			return temp;
		});
		setPrevWidth(width);
		// eslint-disable-next-line
  }, [width]);


	return (
		<>
			{mobile ?
				<>
					<Paper sx={{
						height: "100vh",
					}}>
						<Grid container direction="column" sx={{
							height: "calc(100% - 70px)",
							backgroundColor: "#73BFB8",
						}}>
							<Grid item sx={{
								width: "100%",
							}}>
								<MobileAppBar
									zIndex={200}
									filterState={filterState}
									setFilterState={setFilterState}
									setFilters={(filters) => {
										const { timeRange, sort } = filters;
										setFilterState(state => ({
											...state,
											dbFilters: filters,
											localTimeRangeFilter: timeRange,
											sort: sort,
										}));
									}}
								/>
							</Grid>
							<Grid
								item
								xs={true}
								alignItems="center"
								justifyContent="center"
								sx={{
									backgroundColor: "primary",
									width: "100%",
									height: "100%",
									pt: 1,
									px: 2,
								}}
							>
								<CardList 
									events={events} 
									setReload={setReload} 
									setAddEventModalState={setAddEventModalState}
								/>
							</Grid>
						</Grid>

						<MobileAppBarBottom />
					</Paper>
				</>
				:
				<>
					<Box sx={{
						position: "absolute",
						right: 25,
						top: 75,
						zIndex: 1,
					}}>
						<ViewToggle
							cardView={true}
							handleChangeView={() => {
								new Promise(r => setTimeout(r, 250))
									.then(() => navigate("/calendar"));
							}}
						/>
					</Box>

					<Grid container direction="column" height="100vh">
						<Grid item>
							<Header />
						</Grid>

						<Grid xs={true} item sx={{ pb: 1 }}>
							<Box sx={{
								height: "100%",
								position: "relative",
							}}>
								<AutoSizer>{({ height }) => (
									<>
										<Resizable height={height} width={columnDivs[0]}
											onResize={(_, { size }) => {
												if (size.width > minSizes[0] * width &&
													columnDivs[1] - size.width > minSizes[1] * width) {
													const temp = [...columnDivs];
													temp[0] = size.width;
													setColumnDivs(temp);
												}
											}}>
											<Box className="box" sx={{
												height: height,
												width: columnDivs[0],
												position: "absolute",
												left: 0,
												top: 0,
											}}>
												<FiltersMenu
													setFilters={(filters) => {
														const { timeRange, sort } = filters;
														setFilterState(state => ({
															...state,
															dbFilters: filters,
															localTimeRangeFilter: timeRange,
															sort: sort,
														}));
													}}
												/>
											</Box>
										</Resizable>
										<Resizable height={height} width={columnDivs[1] - columnDivs[0]}
											onResize={(_, { size }) => {
												if (size.width > minSizes[1] * width &&
                          width - columnDivs[0] - size.width - 10 > minSizes[2] * width) {
													const temp = [...columnDivs];
													temp[1] = temp[0] + size.width;
													setColumnDivs(temp);
												}
											}}>
											<Box className="box" sx={{
												height: height - 50,
												width: columnDivs[1] - columnDivs[0],
												position: "absolute",
												left: columnDivs[0],
												top: 50,
											}}>
												{loading ?
													<Box sx={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}>
														<CircularProgress color="primary" />
													</Box>
													:
													<Grid container direction="column" spacing={2} sx={{
														height: "100%",
													}}>
														<Grid item>
															<SortEvents filterState={filterState} setFilterState={setFilterState} />
														</Grid>
														<Grid item xs={true}>
															<Virtuoso
																ref={virtuoso}
																initialTopMostItemIndex={(index && index > -1) ? index : 0}
																style={{ height: "100%" }}
																data={events}
																itemContent={(_index, event) => (
																	<EventCard
																		mobile={mobile}
																		event={event}
																		setReload={setReload}
																		setAddEventModalState={setAddEventModalState}
																		animate={index === _index}
																	/>
																)}
															/>
														</Grid>
													</Grid>
												}
											</Box>
										</Resizable>
										<Box sx={{
											height: "calc(100% - 50px)",
											width: width - columnDivs[1] - 10,
											position: "absolute",
											left: columnDivs[1],
											top: 50,
										}}>
											<Map
												events={events}
												filter={localDestinationLocationFilter}
												setFilter={(filter) => setFilterState(state => ({
													...state,
													localDestinationLocationFilter: filter,
												}))} />
										</Box>
									</>
								)}</AutoSizer>
							</Box>
						</Grid>
					</Grid>
				</>
			}
		</>
	);
}

export default CardView;

CardView.propTypes = {
	mobile: PropTypes.bool.isRequired,
};
