import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, IconButton, Divider, List, ListItem } from "@mui/material";

// local
import SortEvents from "../reusable/SortEvents";
import FilterItems from "../reusable/FilterItems";

// constants
const DRAWER_BLEEDING = 56;

// custom box styling
const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

// custom puller styling
const Puller = styled(Box)(({ theme }) => ({
	width: 30,
	height: 6,
	backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
	borderRadius: 3,
	position: "absolute",
	top: 8,
	left: "calc(50% - 15px)",
}));


export default function FilterDrawer(props) {
	const { toggleDrawer, filterState, setFilterState, setFilters } = props;


	return (
		<>
			<StyledBox
				sx={{
					position: "absolute",
					top: -DRAWER_BLEEDING,
					borderTopLeftRadius: 8,
					borderTopRightRadius: 8,
					visibility: "invisible",
					right: 0,
					left: 0,
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center"
				}}
			>
				<Puller />
				<IconButton
					size="large"
					aria-label="show more"
					aria-haspopup="true"
					onClick={toggleDrawer(false)}
					color="inherit"
					cursor="pointer"
					sx={{ backgroundColor: "transparent", paddingRight: "30%", mt: 2 }}
				>
					<CloseIcon sx={{}} />
				</IconButton>
				<Typography sx={{ p: 1.3, mt: 2, color: "text.secondary" }} variant="h5">Filters</Typography>
			</StyledBox>

			<StyledBox
				sx={{
					px: 2,
					pb: 2,
					height: "90%",
					overflow: "auto",
				}}
			>
				<List>
					<ListItem sx={{ py: 2 }}>
						<SortEvents filterState={filterState} setFilterState={setFilterState} />
					</ListItem>
					<Divider />
					<FilterItems setFilters={setFilters} toggleDrawer={toggleDrawer}/>
				</List>
			</StyledBox>
		</>
	);
}

FilterDrawer.propTypes = {
	toggleDrawer: PropTypes.func.isRequired,
	filterState: PropTypes.object.isRequired,
	setFilterState: PropTypes.func.isRequired,
	setFilters: PropTypes.func.isRequired,
};
