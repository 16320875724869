import PropTypes from "prop-types";
import { Box } from "@mui/material";

// local
import EventScroll from "./EventScroll";
import RegisterBar from "./RegisterBar";


export default function EventComponent(props) {
	const { event, setReload, mobile } = props;

	return (
		<>
			<Box sx={{ 
				display: "flex", 
				flexDirection: "column",
				position: mobile ? "absolute" : undefined,
				top: 0,
				height: mobile ? "100vh" : "100%",
				width: "100%",
			}}>
				<Box sx={{ flexGrow: 1 }}>
					<EventScroll event={event}/>
				</Box>
				<Box sx={{}}>
					<RegisterBar 
						event={event}
						setReload={setReload}
						mobile={mobile}
					/>
				</Box>
			</Box>
		</>
	);
}

EventComponent.propTypes = {
	event: PropTypes.object.isRequired,
	setReload: PropTypes.func.isRequired,
	mobile: PropTypes.bool.isRequired,
};
