import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { CalendarPicker } from "@mui/lab";
import PickersDay from "@mui/lab/PickersDay";
import { Box, TextField, Badge } from "@mui/material";

// local
import { areDatesOnSameDay } from "../../utils/date";

// custom calendar styling
const CustomCalendarPicker = styled(CalendarPicker)({
	"&.MuiCalendarPicker-root *": {
		color: "white",
		fontFamily: "Gudea",
		fontWeight: "bold",
		letterSpacing: 1,
		marginBottom: "-1px",
	},
});

// custom today styling
const CustomPickersDay = styled(PickersDay)({
	background: "rgba(0, 0, 0, 0)",
	"&.MuiPickersDay-today": {
		border: "1px solid rgba(0, 0, 0, 0.1)",
	}
});

// custom badge styling
const StyledBadge = styled(Badge)(() => ({
	"& .MuiBadge-badge": {
		right: 20,
		top: 30,
		minWidth: 5,
		height: 5,
	},
}));


export default function Calendar(props) {
	const { value, setValue, events } = props;

	// map events to start date
	const dates = events.map(event => event.start);


	return (
		<>
			<Box>
				<CustomCalendarPicker
					displayStaticWrapperAs="desktop"
					views={["day"]}
					value={value}
					onChange={(newValue) => {
						setValue(newValue);
					}}
					renderInput={(params) => <TextField {...params} />}
					renderDay={(day, _value, pickersDayProps) => {
						const hasEvents = dates.filter(date => areDatesOnSameDay(date, day)).length > 0;

						return (
							<StyledBadge 
								key={day.toString()}
								variant="dot"
								color="accent"
								invisible={!hasEvents}
							>
								<CustomPickersDay
									{...pickersDayProps}
								/>
							</StyledBadge>
						);
					}}
				/>
			</Box>
		</>
	);
}

Calendar.propTypes = {
	value: PropTypes.any.isRequired,
	setValue: PropTypes.func.isRequired,
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
};
