import { Box } from "@mui/material";

// local
import AccountInfo from "../components/reusable/AccountInfo";
import MobileAppBarBottom from "../components/mobile/MobileAppBarBottom";


function AccountView() {

	return (
		<>
			<Box sx={{
				transform: "translate(-50%, -50%)",
				position: "absolute",
				top: "50%",
				left: "50%",
				width: "100%",
			}}>
				<AccountInfo hideColorMode={true} />
			</Box>

			<MobileAppBarBottom />
		</>
	);
}

export default AccountView;
