import axios from "axios";
import PropTypes from "prop-types";
import throttle from "lodash/throttle";
import { useState, useEffect, useMemo } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

// local
import PrefilledLocations from "../../utils/PrefilledLocations";

// constants
const COMMON_LOCATIONS = PrefilledLocations();
const MAPBOX_URL = "https://api.mapbox.com/geocoding/v5/mapbox.places";
const MAPBOX_PARAMS = `access_token=${process.env.REACT_APP_MAPBOX_API_KEY}&country=us&proximity=ip`;

// global
const filter = createFilterOptions();


export default function PlacesAutocomplete(props) {
	const { label, inputValue, setInputValue, value, setValue } = props;

	// states
	const [options, setOptions] = useState([]);

	// throttle and fetch results from mapbox API
	const fetch = useMemo(
		() =>
			throttle((request, callback) => {
				axios.get(`${MAPBOX_URL}/${request.input}.json?${MAPBOX_PARAMS}`)
					.then(response => {
						callback(response.data.features);
					});
			}, 600),
		[],
	);

	// effects

	useEffect(() => {
		let active = true;

		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);


	return (
		<Autocomplete
			freeSolo
			autoComplete
			filterSelectedOptions
			options={options}
			filterOptions={(options, params) => {
				const filtered = filter(COMMON_LOCATIONS, params);
				return [ ...filtered, ...options];
			}}
			value={value}
			inputValue={inputValue}
			onChange={(event, newValue) => {
				event.stopPropagation();
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
			}}
			onInputChange={(event, newInputValue, reason) => {
				if (reason !== "reset") setInputValue(newInputValue);
			}}
			renderInput={(params) => (
				<TextField 
					{...params} 
					label={label}
					size="large"
					sx={{
						width: "100%",
					}}
				/>
			)}
			getOptionLabel={(option) =>
				typeof option === "string" ? option : option.place_name
			}
			renderOption={(props, option) => {
				return (
					// eslint-disable-next-line react/prop-types
					<li {...props} key={props["data-option-index"]}>
						<Grid container alignItems="center">
							<Grid item>
								<Box
									component={LocationOnIcon}
									sx={{ color: "black", mr: 2 }}
								/>
							</Grid>
							<Grid item xs>
								{option.text}
								<Typography variant="body2" color="text.secondary">
									{option.place_name}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
}

PlacesAutocomplete.propTypes = {
	label: PropTypes.string.isRequired,
	inputValue: PropTypes.any.isRequired,
	setInputValue: PropTypes.func.isRequired,
	value: PropTypes.any,
	setValue: PropTypes.func.isRequired,
};
