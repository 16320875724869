import PropTypes from "prop-types";
import { Virtuoso } from "react-virtuoso";
import { Resizable } from "react-resizable";
import { AutoSizer } from "react-virtualized";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";

// local
import EventCard from "../components/reusable/EventCard";
import FiltersMenu from "../components/desktop/FiltersMenu";
import useWindowDimensions from "../utils/window";
import EventsCalendar from "../components/desktop/EventsCalendar";
import Header from "../components/desktop/Header";
import MobileAppBarBottom from "../components/mobile/MobileAppBarBottom";
import EventsContext from "../contexts/EventsContext";
import ViewToggle from "../components/desktop/ViewToggle";
import { areDatesOnSameDay } from "../utils/date";
import { useSetReload } from "../utils/EventsProvider";
import Calendar from "../components/mobile/Calendar";

// styles
import "react-resizable/css/styles.css";

// constants
const minSizes = [0.2, 0.6];


function CalendarView(props) {
	const { mobile } = props;
	const { setReload } = useSetReload();

	// events context
	const eventsContext = useContext(EventsContext);
	const { events, loading, setFilterState, setAddEventModalState } = eventsContext;

	// hooks
	const navigate = useNavigate();
	const { width } = useWindowDimensions();

	// states

	const [date, setDate] = useState(new Date());
	const [filteredEvents, setFilteredEvents] = useState(events);

	// manage position of columns 
	const [prevWidth, setPrevWidth] = useState(width);
	const [columnDivs, setColumnDivs] = useState([width * 0.3]);

	// manage width change
	useEffect(() => {
		const dif = width - prevWidth;
		setColumnDivs(columnDivs => {
			let temp = [...columnDivs];
			temp[0] = temp[0] + dif / 2;
			if (temp[0] < minSizes[0] * width ||
				width - temp[0] < minSizes[1] * width) {
				temp = [width * 0.3];
			}
			return temp;
		});
		setPrevWidth(width);
		// eslint-disable-next-line
	}, [width]);

	// filter events already fetched from DB
	useEffect(() => {
		setFilteredEvents(
			date ?
				events.filter((event) => {
					const start = event.start;
					return areDatesOnSameDay(start, date);
				})
				:
				events
		);
	}, [date, events]);


	return (
		<>
			{mobile ?
				<>
					<Grid container direction="column" sx={{
						position: "absolute",
						left: 0,
						top: 0,
						height: "calc(100% - 70px)",
						weight: "100%",
						backgroundColor: "#73BFB8",
					}}>
						<Grid item sx={{
							width: "100%",
						}}>
							<Calendar
								value={date}
								setValue={setDate}
								events={events}
								loading={loading}
							/>
						</Grid>
						<Grid item xs sx={{
							mt: -4,
							bottom: 0,
						}}>
							<Box sx={{
								backgroundColor: "#EBEBEB",
								height: "100%",
								borderTopLeftRadius: 35,
								borderTopRightRadius: 35,
								py: 3,
								px: 2,
							}}>
								<Grid container direction="column" sx={{
									height: "100%",
								}}>
									<Grid item>
										<Typography sx={{
											p: 1,
											fontSize: 24,
										}}>
											{date.toLocaleDateString([], {
												month: "long",
												day: "numeric",
												weekday: "long",
											})}
										</Typography>
									</Grid>
									{filteredEvents.length === 0 &&
										<Grid item xs>
											<Typography sx={{
												p: 1,
											}}>
												No Events Found
											</Typography>
										</Grid>
									}
									<Grid item xs>
										{loading ?
											<Box sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}>
												<CircularProgress color="primary" />
											</Box>
											:
											<Virtuoso
												style={{ height: "100%" }}
												data={filteredEvents}
												itemContent={(_, event) => (
													<EventCard
														mobile={true}
														event={event}
														setReload={setReload}
														setAddEventModalState={setAddEventModalState}
													/>
												)}
											/>
										}
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
					<MobileAppBarBottom />
				</>
				:
				<>
					<Header />
					<Box sx={{
						position: "absolute",
						right: 25,
						top: 75,
						zIndex: 1,
					}}>
						<ViewToggle
							cardView={false}
							handleChangeView={() => {
								new Promise(r => setTimeout(r, 250))
									.then(() => navigate("/card"));
							}}
						/>
					</Box>

					<Box sx={{
						height: "calc(100vh - 85px)",
						position: "relative",
					}}>
						<AutoSizer>{({ height }) => (
							<>
								<Resizable height={height} width={columnDivs[0]}
									onResize={(_, { size }) => {
										if (size.width > minSizes[0] * width &&
											width - size.width - 10 > minSizes[1] * width) {
											const temp = [...columnDivs];
											temp[0] = size.width;
											setColumnDivs(temp);
										}
									}}>
									<Box className="box" sx={{
										height: height,
										width: columnDivs[0],
										position: "absolute",
										left: 0,
										top: 0,
										zIndex: 2,
									}}>
										<FiltersMenu
											setFilters={(filters) => {
												const { timeRange, sort } = filters;
												setFilterState(state => ({
													...state,
													dbFilters: filters,
													localTimeRangeFilter: timeRange,
													sort: sort,
												}));
											}}
											hideSort={true}
										/>
									</Box>
								</Resizable>
								<Box sx={{
									height: "calc(100% - 50px)",
									width: width - columnDivs[0] - 30,
									position: "absolute",
									left: columnDivs[0] + 10,
									top: 50,
									zIndex: 1,
								}}>
									<EventsCalendar
										events={events}
										setAddEventModalState={setAddEventModalState}
									/>
								</Box>
							</>
						)}</AutoSizer>
					</Box>
				</>
			}
		</>
	);
}

export default CalendarView;

CalendarView.propTypes = {
	mobile: PropTypes.bool.isRequired,
};
