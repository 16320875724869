import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";

// local
import Navbar from "../components/desktop/Navbar";
import Footer from "../components/desktop/Footer";
import LandingPageContent from "../components/desktop/LandingPageContent";
import LandingPageContentMain from "../components/desktop/LandingPageContentMain";
import LandingPageContentDesktop from "../components/desktop/LandingPageContentDesktop";

// assets
import chat_png from "../assets/landing_page/chat.png";
import mobile_png from "../assets/landing_page/mobile.png";
import calendar_png from "../assets/landing_page/calendar.png";


function ElevationScroll(props) {
	const { children, window } = props;
	// Note that you normally won"t need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}


function ElevateAppBar(props) {
	const { loginPath } = props;

	return (
		<>
			<CssBaseline />
			<ElevationScroll {...props}>
				<Navbar loginPath={loginPath}/>
			</ElevationScroll>
		</>
	);
}


export default function LandingView(props) {
	const { hideAppStoreButton, loginPath } = props;


	return (
		<>
			<Grid container direction="column" sx={{ 
				width: "100%", 
				overflow: "hidden", 
				background: "#fff" 
			}}>
				<Grid item sx={{ width: "100%", height: 100 }}>
					<ElevateAppBar loginPath={loginPath} sx={{ width: "100%" }}/>
				</Grid>
				<Grid item sx={{ width: "100%", px: 2, pb: 10 }}>
					<LandingPageContentMain hideAppStoreButton={hideAppStoreButton} loginPath={loginPath}/>
				</Grid>
				<Grid item sx={{ width: "100%", px: 2, pb: 10 }}>
					<LandingPageContent padding={6} image={mobile_png} text1={"ON-DEMAND SCHEDULING"} text2={"Create an Event"} 
						text3={"Easily create new rideshare events to save money on transportation"}/>
				</Grid>
				<Grid item sx={{ width: "100%", px: 2, pb: 10 }}>
					<LandingPageContentDesktop image={calendar_png} text1={"EVENT SEARCH"} text2={"Join a Rideshare"} 
						text3={"Browse our communal calendar to join events created by other students at your university"}/>
				</Grid>
				<Grid item sx={{ width: "100%", px: 2, pb: 5 }}>
					<LandingPageContent padding={0} image={chat_png} text1={"REAL-TIME MESSAGING"} text2={"Chat with Friends"} 
						text3={"Use our integrated chat feature to communicate with other members of your trip"}/>
				</Grid>
				<Grid item sx={{ width: "100%" }}>
					<Footer/>
				</Grid>
			</Grid>
    
		</>
	);
}

LandingView.propTypes = {
	mobile: PropTypes.bool.isRequired,
	hideAppStoreButton: PropTypes.bool,
	loginPath: PropTypes.string.isRequired,
};

ElevateAppBar.propTypes = {
	loginPath: PropTypes.string.isRequired,
};
