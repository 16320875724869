import Toggle from "react-toggle";
import PropTypes from "prop-types";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

// local
import "../../styles/react-toggle.css";


export default function ViewToggle(props) {
	const { cardView, handleChangeView } = props;


	return (
		<Toggle
			defaultChecked={cardView}
			icons={{
				checked: <ViewAgendaOutlinedIcon 
					sx={{ 
						color: "White",
						mt: "-4px", 
						ml: "-5px", 
						height: 18,
					}}
				/>, 
				unchecked: <CalendarTodayOutlinedIcon 
					sx={{ 
						color: "White", 
						mt: "-3px", 
						ml: "-7px",
						height: 16,
					}}
				/>
			}}
			onChange={handleChangeView}
		/>
	);
}

ViewToggle.propTypes = {
	cardView: PropTypes.bool.isRequired,
	handleChangeView: PropTypes.func.isRequired,
};
