import axios from "axios";

// if you don"t have a BASE_URL in your config.env,
// then it will try to access a local build.
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

/**
 * Gets a list of all users.
 * @returns list of all user objects.
 */
async function getAllUsers(token) {
	const response = await axios.get(`${BASE_URL}/api/users/all`, {
		headers: { Authorization: `Bearer ${token}` },
	});
	return response;
}


function reportUser(token, userID, type, description) {
	return axios.post(`${BASE_URL}/api/report`, {
		userID: userID,
		reportType: type,
		description: description,
	}, {
		headers: { Authorization: `Bearer ${token}` },
	});
}

/**
 * Gets a singular user.
 * @param {_id} userID the specific user being queried for.
 * @returns the user object.
 */
async function getUser(token) {
	const response = await axios.get(`${BASE_URL}/api/users/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
	return response;
}

async function addUserToEvent(params, token) {
	let { userID, eventID } = params;
	await axios.put(`${BASE_URL}/api/events/${eventID}`, { 
		user: userID,
		addPassenger: true, 
	}, {
		headers: { Authorization: `Bearer ${token}` },
	});
}
 
async function removeUserFromEvent(params, token) {
	let { userID, eventID } = params;
	return await axios.put(`${BASE_URL}/api/events/${eventID}`, { 
		user: userID,
		removePassenger: true, 
	}, {
		headers: { Authorization: `Bearer ${token}` },
	});
}

async function createUser(username, jhed, email, venmo, token, referralToken) {
	venmo = venmo.replace("@", "");
	return axios.post(`${BASE_URL}/api/users`, {
		username: username,
		jhed: jhed,
		email: email,
		venmo: venmo,
		referralToken: referralToken || undefined,
	}, {
		headers: { Authorization: `Bearer ${token}` },
	});
}

async function modifyUser(username, token, subscribed, venmo) {
	if (venmo) {
		venmo = venmo.replace("@", "");
	}
	return axios.put(`${BASE_URL}/api/users/`, {
		username: username,
		subscribed: subscribed,
		venmo: venmo,
	}, {
		headers: { Authorization: `Bearer ${token}` },
	});
}

async function finishedDemo(token) {
	return axios.put(`${BASE_URL}/api/users/intro`,
		{
			headers: {Authorization: `Bearer${token}`},
		});
}

export { getAllUsers, getUser, addUserToEvent, removeUserFromEvent, createUser, modifyUser, finishedDemo, reportUser };
