import { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useTheme, Toolbar, Grid, Typography } from "@mui/material";

// local
import Title from "../components/mobile/Title";
import Header from "../components/desktop/Header";
import ChatList from "../components/reusable/ChatList";
import GroupChatsList from "../components/reusable/GroupChatsList";
import EventComponent from "../components/reusable/EventComponent";


function ChatView(props) {
	const { mobile } = props;
	const { id } = useParams();

	const theme = useTheme();
	const { mode } = theme.palette;

	// states
	const [reload, setReload] = useState(true);
	const [eventDetails, setEventDetails] = useState({
		eventId: null,
		eventTitle: null,
		event: null,
	});


	return (
		<>  
			{ mobile ? 
				<>
					<Title text={"Chat"}/>
					<Toolbar/>
					<Grid sx={{width: "100%", height: "90vh", px: 1 }}>
						<ChatList eventId={id}/>
					</Grid>
				</>
				:
				<>
					<Header/>

					<Grid container direction="row" sx={{
						height: "calc(100vh - 85px)",
						position: "relative",
					}}>
						<Grid item xs={3.5} sx={{
							borderRightStyle: "solid",
							borderWidth: 1,
							borderColor: "#ADADAD",
						}}>
							<Grid container direction="column" sx={{
								height: "100%"
							}}>
								<Grid item sx={{
									borderBottomStyle: "solid",
									borderWidth: 1,
									borderColor: "#ADADAD",
								}}>
									<Typography sx={{ 
										my: 1, 
										ml: 2,
										fontFamily: "Mulish",
										fontWieght: "bold",
										fontSize: 26,
										color: mode === "light" ? "#666666" : undefined,
									}}>
                                        Chat
									</Typography>
								</Grid>
								<Grid item xs sx={{ mt: 1, mx: 1, height: "100%" }}>
									<GroupChatsList mobile={mobile} setEvent={setEventDetails} reload={reload} setReload={setReload}/>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={4.5} sx={{
							height: "100%",
							borderRightStyle: "solid",
							borderWidth: 1,
							borderColor: "#ADADAD",
						}}>
							<Grid container direction="column" sx={{ height: "100%" }}>
								<Grid item sx={{
									borderBottomStyle: "solid",
									borderWidth: 1,
									borderColor: "#ADADAD",
									width: "100%"
								}}>
									<Typography sx={{ 
										my: 1, 
										ml: 2,
										fontFamily: "Mulish",
										fontWieght: "bold",
										fontSize: 26,
										color: eventDetails.eventTitle 
											? 
											mode === "light" ? "#020202" : "white"
											: 
											"rgb(0,0,0,0)",
										userSelect: eventDetails.eventTitle ? undefined : "none",
									}}>
										{eventDetails.eventTitle ? eventDetails.eventTitle : "HIDDEN"}
									</Typography>
								</Grid>
								<Grid item xs={true} sx={{ mt: 1 }}>
									{eventDetails.eventId && <ChatList eventId={eventDetails.eventId}/>}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4} sx={{
							height: "100%",
						}}>
							{eventDetails.event && <EventComponent event={eventDetails.event} setReload={setReload} mobile={false}/>}
						</Grid>
					</Grid>
				</>
			}
		</>
	);
}

export default ChatView;

ChatView.propTypes = {
	mobile: PropTypes.bool.isRequired,
};
