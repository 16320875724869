import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";


export default function LandingPageContentDesktop(props) {
	const { image, text1, text2, text3 } = props;


	return (
		<>
			<Grid container spacing={2} width="100%">
				<Grid item md={6} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", zIndex: 1, px: 2 }}>
						<Typography variant="h5">
							{text1}
						</Typography>
						<Typography variant="h3" pt={1.5} pb={2} sx={{ fontWeight: "bold", wordWrap: "break-word", lineHeight: 1.1, fontFamily: "Montserrat" }}>
							{text2}
						</Typography>
						<Typography variant="h5" sx={{ fontWeight: 300, wordWrap: "break-word", lineHeight: 1.2 }}>
							{text3}
						</Typography>
					</Box>
				</Grid>
				<Grid item md={6} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center",  }}>
					<Box
						component="img"
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							transform: "scale(1.2)",
						}}
						src={image}
					/>
				</Grid>
			</Grid>
		</>
	);
}

LandingPageContentDesktop.propTypes = {
	image: PropTypes.any.isRequired,
	text1: PropTypes.string.isRequired,
	text2: PropTypes.string.isRequired,
	text3: PropTypes.string.isRequired,
};
