import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import { Typography, IconButton, Box } from "@mui/material";


export default function Filter(props) {
	const { filterText, clear } = props;


	return (
		<Box sx={{
			width: "100%",
			display: "flex",
			flexDirection: "row-reverse",
			alignItems: "center",
		}}>
			{filterText &&
				<IconButton sx={{ transform: "scale(0.6)" }}
					onClick={(e) => {
						e.stopPropagation();
						clear();
					}}>
					<ClearIcon />
				</IconButton>
			}
			<Typography align="right" sx={{ color: "text.secondary", width: "fit-content" }}>
				{filterText}
			</Typography>
		</Box>
	);
}

Filter.propTypes = {
	filterText: PropTypes.string.isRequired,
	clear: PropTypes.func.isRequired,
};
