import { useState } from "react";
import PropTypes from "prop-types";
import parse from "date-fns/parse";
import { Grid } from "@mui/material";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";

// local
import EventModal from "./EventModal";

// styles
import "react-big-calendar/lib/css/react-big-calendar.css";

// constants
const CONFIG_LOCALES = {
	"en-US": enUS,
};
const LOCALIZER = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales: CONFIG_LOCALES,
});


export default function EventsCalendar(props) {
	const { events, setReload, setAddEventModalState } = props;

	// states
	const [eventModalState, setEventModalState] = useState({ open: false, event: null });

	// map events to calendar format
	const data = events.map(event => {
		return {
			...event,
			title: !event.title ? `Trip to ${event.destination.name}` : event.title,
			allDay: false,
		};
	});


	return (
		<Grid
			container
			spacing={0}
			alignItems="center"
			justifyContent="center"
			height={"100%"} // nonscrolling height
		>
			<Calendar
				localizer={LOCALIZER}
				defaultDate={new Date()}
				defaultView="month"
				views={["month", "week", "day"]}
				events={data}
				style={{ width: "100%", height: "100%", fontFamily: "Muli" }}
				eventPropGetter={() => ({
					style: {
						backgroundColor: "#3DA5D9", // changing event color
						border: "0px",
						display: "block"
					}
				})}
				onSelectEvent={event => setEventModalState({ open: true, event: event})} // opens modal on select
			/>
			<EventModal 
				open={eventModalState.open} 
				setOpen={() => setEventModalState({ ...eventModalState, open: false })} 
				event={eventModalState.event} 
				setReload={setReload}
				setAddEventModalState={setAddEventModalState}
			/>
		</Grid>
	);
}

EventsCalendar.propTypes = {
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	setReload: PropTypes.func,
	setAddEventModalState: PropTypes.func.isRequired,
};
