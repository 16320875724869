import { createContext } from "react";

const EventContext = createContext({
	events: [],
	loading: false,
	setFilterState: null,
	setReload: null,
	localDestinationLocationFilter: null,
	setAddEventModalState: null,
	filterState: null,
});

export default EventContext;
