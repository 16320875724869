import bbox from "@turf/bbox";
import mapboxgl from "!mapbox-gl";
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { lineString as makeLineString} from "@turf/helpers";


export default function Map(props) {
	const { event } = props;

	// hooks

	const map = useRef(null);
	const mapContainer = useRef(null);

	const _theme = useTheme();
	const { mode } = _theme.palette;

	// states
	const [theme, setTheme] = useState(mode);
	const [markers, setMarkers] = useState([]);

	// effects

	/* eslint-disable */
    useEffect(() => {
        if (map.current && theme === mode) {
            map.current.resize();
            return; // initialize map only once
        }

        setTheme(mode);
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mode === "light" ? "mapbox://styles/mapbox/streets-v11" : "mapbox://styles/mapbox/dark-v10",
            center: [-76.611916, 39.306472],
        });
    });
    /* eslint-enable */

	useEffect(() => {
		// remove prior markers
		for (const marker of markers) {
			marker.remove();
		}

		// get list of coordinates
		const coords = [];
		const locations = event ? [event.destination.location, event.departure.location] : null;
        
		for (const location of locations) {
			if (!location) continue;
			coords.push(location);
		}

		// fit map to bounds
		let bounds;
		if (coords.length > 1) {
			bounds = bbox(makeLineString(coords));
		} else if (coords.length === 1) {
			bounds = bbox(makeLineString([coords[0], coords[0]]));
		} else {
			// Baltimore bbox by default
			bounds = bbox(makeLineString([[-76.715943, 39.200397], [-76.523339, 39.372058]]));
		}
		map.current.fitBounds(bounds, {padding: 50, maxZoom: 12, duration: 2000});

		// Create a marker and button 
		const tempMarkers = [];
		for (const location of locations) {
			if (!location) continue;

			const lng = location[0];
			const lat = location[1];

			const marker = new mapboxgl.Marker()
				.setLngLat([lng, lat])
				.addTo(map.current);
			tempMarkers.push(marker);
		}
		setMarkers(tempMarkers);
		// eslint-disable-next-line
    }, [event, theme]);
    

	return (
		<Box 
			ref={mapContainer} 
			className="map-container"
			sx={{
				height: "100%",
			}}
		/>
	);
}

Map.propTypes = {
	event: PropTypes.object.isRequired,
};
