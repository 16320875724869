import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";


export default function ViewReceipt(props) {
	const { receiptUrl } = props;


	return (
		<>
			<PopupState variant="popover" popupId="demo-popup-popover">
				{(popupState) => (
					<div>
						<Button
							{...bindTrigger(popupState)}
							variant="contained"
							sx={{
								textTransform: "none",
							}}
						>
                            View Receipt
						</Button>
						<Popover
							{...bindPopover(popupState)}
							anchorOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
						>
							<Box sx={{
								height: "70vh",
								width: "80vw",
								display: "flex",
								justifyContent: "center",
								p: 1,
							}}>
								<img src={receiptUrl} alt="Rideshare receipt" style={{
									maxHeight: "100%",
								}} />
							</Box>
						</Popover>
					</div>
				)}
			</PopupState>
		</>
	);
}

ViewReceipt.propTypes = {
	receiptUrl: PropTypes.string.isRequired,
};
