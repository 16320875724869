import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useState, useEffect, useContext } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Box, AppBar, Toolbar, Button, Grid, Typography, Popover, IconButton, TextField } from "@mui/material";

// local
import AlertDialog from "../mobile/AlertDialog";
import AuthContext from "../../contexts/AuthContext";
import AlertContext from "../../contexts/AlertContext";
import ViewReceipt from "../mobile/ViewReceipt";
import { addUserToEvent, removeUserFromEvent } from "../../services/users";
import { getRideDetails, uploadRideDetails, clearRideDetails } from "../../services/events";


export default function RegisterBar(props) {
	const { event, setReload, mobile } = props;

	const start = event.start.getTime();
	const today = new Date();
	const oneHourBefore = new Date(start - 60 * 60 * 1000);
	const oneWeekAfter = new Date(start + 7 * 24 * 60 * 60 * 1000);
	const duringEvent = today > oneHourBefore && today < oneWeekAfter;

	// states
	const [rideDetails, setRideDetails] = useState({
		file: null,
		total: null,
		imageURL: null,
	});
	const [open, setOpen] = useState(false);

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// auth
	const authContext = useContext(AuthContext);
	const { token, user } = authContext;
	const isHost = event.owner === user._id;

	// hooks
	const navigate = useNavigate();

	const joined = event.passengers.indexOf(user._id) > -1;

	// effects

	useEffect(() => {
		if (joined && event.receipt) {
			getRideDetails(event._id, token)
				.then((res) => {
					setRideDetails({
						imageURL: res.data.url,
						total: res.data.total,
					});
				})
				.catch((err) => {
					setAlert({ message: "Error: Something went wrong. Please try again.", open: true, severity: "error" });
					console.log(err);
				});
		}
	}, [event.receipt, event._id, joined, setAlert, token]);

	// functions

	function handleJoinEvent() {
		addUserToEvent({ eventID: event._id, userID: user._id }, token)
			.then(() => {
				// on success
				setAlert({ message: "Successfully joined event", open: true, severity: "success" });
				setReload(true);
			})
			.catch((err) => {
				setAlert({ message: "Error: Something went wrong. Please try again.", open: true, severity: "error" });
				console.log(err);
			});
	}

	function handleDropEvent() {
		removeUserFromEvent({ eventID: event._id, userID: user._id }, token)
			.then(() => {
				// on success
				setAlert({ message: "Successfully dropped event", open: true, severity: "success" });
				setReload(true);
			})
			.catch((err) => {
				setAlert({ message: "Error: Something went wrong. Please try again.", open: true, severity: "error" });
				console.log(err);
			});
	}

	function handleSubmitRide() {
		if (!rideDetails.file) {
			setAlert({ message: "Please upload a file!", open: true, severity: "error" });
			return;
		}
		const regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
		if (!rideDetails.total || !regex.test(rideDetails.total)) {
			setAlert({ message: "Please provide a valid ride total!", open: true, severity: "error" });
			return;
		}
		uploadRideDetails(rideDetails.file, rideDetails.total, event._id, token)
			.then(() => {
				setAlert({ message: "Uploaded ride information!", open: true, severity: "success" });
				setReload(true);
			})
			.catch((error) => {
				setAlert({ message: "Error: Something went wrong. Please try again.", open: true, severity: "error" });
				console.log(error);
			});
	}

	function handleClearRide() {
		clearRideDetails(event._id, token)
			.then(() => {
				setAlert({ message: "Cleared ride information!", open: true, severity: "success" });
				setReload(true);
			})
			.catch((err) => {
				setAlert({ message: "Error: Something went wrong. Please try again.", open: true, severity: "error" });
				console.log(err);
			});
	}


	return (
		<>
			<AlertDialog open={open} setOpen={setOpen} handleDropEvent={handleDropEvent} />

			<AppBar
				position="relative"
				color="white"
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%"
				}}
			>
				<Grid container direction="column">
					{((duringEvent || process.env.REACT_APP_DEBUG_DURING_EVENT) && joined) &&
						<>
							<Grid item sx={{ pt: 2, pb: 1 }}>
								<Grid container direction="row" spacing={1} sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}>
									<Grid item>
										<Typography>
											{isHost ? "You are the event host" : "You are a rider"}
										</Typography>
									</Grid>
									<Grid item>
										<PopupState variant="popover" popupId="demo-popup-popover">
											{(popupState) => (
												<div>
													<IconButton {...bindTrigger(popupState)} sx={{
														height: 30,
														width: 30,
													}}>
														<InfoIcon sx={{
															height: 20,
															width: 20,
														}} />
													</IconButton>
													<Popover
														{...bindPopover(popupState)}
														anchorOrigin={{
															vertical: "top",
															horizontal: "center",
														}}
														transformOrigin={{
															vertical: "bottom",
															horizontal: "center",
														}}
													>
														<Typography sx={{ p: 2 }}>
															{isHost ?
																"Please book the rideshare and upload a screenshot of your receipt"
																:
																"The event host will book your rideshare and upload a screenshot of their receipt.  Please use the button below to pay them back"
															}
														</Typography>
													</Popover>
												</div>
											)}
										</PopupState>
									</Grid>
								</Grid>
							</Grid>
							<Grid item sx={{
								display: "flex",
								justifyContent: "center",
								gap: 2,
								pb: mobile ? undefined : 2,
							}}>
								{isHost ?
									<>
										{event.receipt ?
											<Grid container direction="row" spacing={2} sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}>
												<Grid item sx={{
													display: "flex",
													alignItems: "center",
												}}>
													<ViewReceipt receiptUrl={rideDetails.imageURL} />
												</Grid>
												<Grid item>
													<Typography>
														Ride Total: ${rideDetails.total}
													</Typography>
												</Grid>
												<Grid item sx={{
													ml: -1,
												}}>
													<IconButton onClick={handleClearRide}>
														<HighlightOffIcon />
													</IconButton>
												</Grid>
											</Grid>
											:
											<Grid container direction="row" spacing={2} sx={{
												px: 2,
											}}>
												<Grid item xs={5} sx={{
													display: "flex",
													alignItems: "center",
												}}>
													<Button
														variant="outlined"
														sx={{
															textTransform: "none",
															width: "100%",
															height: "100%",
														}}
														component="label"
													>
														<input
															type="file"
															hidden
															onChange={(e) => {
																if (!e.target.files) {
																	return;
																}
																const file = e.target.files[0];
																setRideDetails((state) => ({
																	...state,
																	file: file
																}));
															}}
														/>
														{rideDetails.file ? rideDetails.file.name : "Upload Screenshot"}
													</Button>
												</Grid>
												<Grid item xs={4} sx={{
													display: "flex",
													alignItems: "center",
												}}>
													<TextField
														label="Total"
														variant="outlined"
														sx={{
															width: "100%",
														}}
														onChange={(e) => setRideDetails((state) => ({
															...state,
															total: e.target.value
														}))}
														inputProps={{
															type: "number",
															min: "0.00",
															max: "10000.00",
															step: "0.01",
														}}
													/>
												</Grid>
												<Grid item xs={3} sx={{
													display: "flex",
													alignItems: "center",
												}}>
													<Button
														variant="contained"
														sx={{
															textTransform: "none",
															width: "100%",
															height: "100%",
														}}
														onClick={handleSubmitRide}
													>
														Submit
													</Button>
												</Grid>
											</Grid>
										}
									</>
									:
									<>
										{event.receipt ?
											<Box sx={{
												display: "flex",
												alignItems: "center",
												gap: 2,
												px: 2,
											}}>
												<ViewReceipt receiptUrl={rideDetails.imageURL} />
												<Button
													sx={{
														backgroundColor: "#0074de",
														borderRadius: "24px",
														fontFamily: "Scto Grotesk A Bold",
														lineHeight: "20px",
														color: "#fff",
														fontSize: "1.125rem",
														fontWeight: 400,
														padding: "12px",
														textTransform: "none",
														gap: 1,
														px: 5,
													}}
													onClick={() => {
														window.open(`https://account.venmo.com/pay?txn=charge&note=${event.title}&amount=${Math.round(event.total / event.passengers.length, 2)}&recipients=${event.venmo}`, "_blank");
													}}
												>
													<Box sx={{ height: 24, width: 24 }}>
														<svg focusable="false" viewBox="0 0 20 20" role="img">
															<path fill="white" fillRule="evenodd" clipRule="evenodd" d="M17.8403 0.77C18.5249 1.87835 18.8338 3.01961 18.8338 4.46184C18.8338 9.06059 14.8274 15.0349 11.5754 19.23H4.14835L1.17 1.77723L7.67325 1.17209L9.2479 13.5911C10.7198 11.242 12.5352 7.55014 12.5352 5.03327C12.5352 3.65605 12.2945 2.71704 11.9181 1.94497L17.8403 0.77Z" />
															<title>Venmo</title>
														</svg>
													</Box>
													Pay ${Math.round(event.total / event.passengers.length, 2)}
												</Button>
											</Box>
											:
											<></>
										}
									</>
								}
							</Grid>
						</>
					}
					{mobile &&
						<Grid item>
							<Toolbar sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								p: 2,
							}}>
								{joined &&
									<Button
										onClick={() => navigate(`/chat/${event._id}`)}
										variant="contained"
										color="secondary"
										sx={{
											mr: 2,
											textTransform: "none",
											color: "#ffffff",
											fontWeight: "bold",
										}}
									>
										Chat
									</Button>
								}
								{joined ?
									<Button
										onClick={() => setOpen(true)}
										variant="contained"
										color="accent"
										sx={{
											textTransform: "none",
											color: "#ffffff",
											fontWeight: "bold"
										}}>
										Leave
									</Button>
									:
									<Button
										onClick={handleJoinEvent}
										variant="contained"
										color="secondary"
										sx={{
											textTransform: "none",
											color: "#ffffff",
											fontWeight: "bold"
										}}
									>
										Join
									</Button>
								}
							</Toolbar>
						</Grid>
					}
				</Grid>
			</AppBar>
		</>
	);
}

RegisterBar.propTypes = {
	mobile: PropTypes.bool.isRequired,
	event: PropTypes.object.isRequired,
	setReload: PropTypes.func.isRequired,
};
