import PropTypes from "prop-types";
import { Grid, Box, Typography } from "@mui/material";

// local
import groupchat_png from "../../assets/groupchat.png";


export default function GroupChat(props) {
	const { event, recent } = props;
	const { title, start } = event;

	const start_date = start.toDateString();

	function convertTime(time) {
		let hr_min_sec = time.toLocaleTimeString("en-US").split(":");
		let am_or_pm = time.toLocaleTimeString("en-US").split(" ")[1];
		return hr_min_sec[0] + ":" + hr_min_sec[1] + " " + am_or_pm;
	}

	return (
		<>
			<Box
				sx={{
					width: "98%",
					p: "1%",
					display: "flex",
					alignItems: "center",
					gap: 1,
					height: "100%",
				}}
				flexDirection="row"
				justifyContent="space-between"
			>
				<Grid container direction="row" alignItems="center" sx={{
					width: "100%",
				}}>
					<Grid item xs="auto">
						<Box
							component="img"
							sx={{
								height: 60,
								width: 60,
								maxHeight: { xs: 70, md: 70 },
								maxWidth: { xs: 70, md: 70 },
								mr: 2,
								ml: 1,
							}}
							alt="The house from the offer."
							src={groupchat_png}
						/>
					</Grid>
					<Grid item xs sx={{
						width: "50%",
					}}>
						<Box display="flex" flexDirection="column">
							<Typography sx={{ fontFamily: "Muli", fontWeight: 1000, fontSize: 15 }}>
								{title}
							</Typography>
							<Typography sx={{ fontSize: 11, mb: 0.5 }}>
								on {start_date} @ {convertTime(start)}
							</Typography>

							{recent && recent.contentType === "text" &&
								<Typography noWrap sx={{ width: "100%", fontSize: 14 }}>
									{recent.sender} : {recent.content}
								</Typography>}
							{recent && recent.contentType === "announcement" &&
								<Typography noWrap sx={{ width: "100%", fontSize: 14 }}>
									{recent.content}
								</Typography>}
						</Box>
					</Grid>
					{recent &&
						<Grid item xs="auto">
							<Typography sx={{
								fontSize: 14,
								textAlign: "right",
								flexGrow: 3,
							}}>
								{convertTime(recent.sent_at)}
							</Typography>
						</Grid>
					}
				</Grid>


			</Box>

		</>
	);
}

GroupChat.propTypes = {
	event: PropTypes.object.isRequired,
	recent: PropTypes.any,
};
