import { GlobalStyles } from "@mui/styled-engine";
import { useMemo, useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useMediaQuery, ThemeProvider, CssBaseline, Snackbar } from "@mui/material";

// local 
import theme from "./styles/theme";
import Alert from "./components/reusable/Alert";
import { login as _login, logout as _logout } from "./utils/auth";

// pages
import ChatView from "./pages/ChatView";
import CardView from "./pages/CardView";
import LoginView from "./pages/LoginView";
import EventView from "./pages/EventView";
import SignupView from "./pages/SignupView";
import LandingView from "./pages/LandingView";
import MyEventsView from "./pages/MyEventsView";
import CalendarView from "./pages/CalendarView";
import LoginCallback from "./utils/LoginCallback";
import GroupChatView from "./pages/GroupChatView";
import ProtectedRoute from "./utils/ProtectedRoute";
import BackdoorLogin from "./pages/BackdoorLoginView";

// utils
import MetricView from "./pages/MetricView";
import AccountView from "./pages/AccountView";
import AuthContext from "./contexts/AuthContext";
import AlertContext from "./contexts/AlertContext";
import EventsProvider from "./utils/EventsProvider";
import ColorModeContext from "./contexts/ColorModeContext";

// fields

// determine if user if on mobile device
const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// track time remaining before logout
let logoutTimer;


function App() {
	const navigate = useNavigate();

	// theme
	const savedMode = localStorage.getItem("mode");
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const [mode, setMode] = useState(savedMode || (prefersDarkMode ? "dark" : "light"));

	/* eslint-disable */
	const generatedTheme = useMemo(
		() =>
			responsiveFontSizes(createTheme({
				...theme,
				palette: {
					...theme.palette,
					...mode === "dark" && {
						primary: {
							main: theme.palette.primary.light,
							light: theme.palette.primary.main,
						}
					},
					mode: mode,
				}
			}),
				[prefersDarkMode, mode],
			));
	/* eslint-enable */

	const colorMode = useMemo(
		() => ({
			toggleColorMode: () => {
				const _mode = mode === "light" ? "dark" : "light";
				localStorage.setItem("mode", _mode);
				setMode(_mode);
			},
		}),
		[mode],
	);

	// manage app reload
	const [authLoaded, setAuthLoaded] = useState(false);

	// manage user authorization 
	const [authState, setAuthState] = useState({
		token: null,
		tokenExpirationDate: null,
		user: null,
	});

	// manage alerts to user
	const [alert, setAlert] = useState({
		open: false,
		message: "",
		severity: null
	});

	// handle alert close event
	const handleCloseAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setAlert(t => ({ ...t, open: false }));
	};

	// add setAuthState to login/logout functions
	const login = (token, tokenExpirationDate, user) => _login(token, tokenExpirationDate, user, (s) => setAuthState(s));
	const logout = () => _logout((s) => {
		setAuthState(s);

		const loginPath = localStorage.getItem("loginPath");
		navigate(loginPath || "/login");
	});

	// hook checks if something is in localStorage and logs user in accordingly
	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem("userData"));

		if (storedData && !authLoaded) {
			let { token, tokenExpirationDate, user } = storedData;
			if (tokenExpirationDate) {
				tokenExpirationDate = new Date(tokenExpirationDate);
				if (token && tokenExpirationDate > new Date() && user) {
					login(token, tokenExpirationDate, user);
				}
			}
		}

		setAuthLoaded(true);
	}, [authLoaded]);

	// set timer if expiration time in future, otherwise clear timer 
	useEffect(() => {
		if (authState.token && authState.tokenExpirationDate) {
			const remainingTime = authState.tokenExpirationDate.getTime() - new Date().getTime();
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
		// eslint-disable-next-line
	}, [authState.token, authState.tokenExpirationDate]);

	const lightMode = createTheme({ ...theme, palette: { ...theme.palette, mode: "light" } });


	return (
		<ThemeProvider theme={generatedTheme}>

			{/* Global style changes */}
			<GlobalStyles
				styles={{
					".rbc-today": {
						backgroundColor: "rgb(0, 0, 0, 0)",
					},
					...generatedTheme.palette.mode === "dark" && {
						".react-resizable-handle": {
							position: "absolute",
							right: "3px",
							bottom: "3px",
							width: "5px",
							height: "5px",
							borderRight: "2px solid rgba(255, 255, 255, 0.4)",
							borderBottom: "2px solid rgba(255, 255, 255, 0.4)",  // Update rgba values for color and transparency.
						},
						".rbc-off-range-bg": {
							backgroundColor: "black",
						},
						".anticon-clock-circle": {
							color: "#fff",
						},
						".timepicker-styles": {
							borderColor: "#525252",
						},
						".ant-picker-input > input::placeholder": {
							color: "#b6b6b6",
						},
						".timepicker-styles:hover": {
							borderColor: "#fff",
						},
						".ant-picker-input > input": {
							color: "#fff"
						}
					}
				}}
			/>

			{/* Alert */}
			<Snackbar
				autoHideDuration={2500}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={alert.open}
				onClose={handleCloseAlert}
				sx={{ zIndex: 6000 }}
			>
				<Alert
					severity={alert.severity}
					onClose={handleCloseAlert}
				>
					{alert.message}
				</Alert>
			</Snackbar>

			<ColorModeContext.Provider value={colorMode}>
				<AlertContext.Provider value={{
					setAlert: setAlert,
				}}>
					<AuthContext.Provider value={{
						user: authState.user,
						token: authState.token,
						login: login,
						logout: logout,
						reload: () => setAuthState(state => ({ ...state })),
					}}>
						<Routes>
							{/* Landing Page */}
							<Route path="/" element={
								<ThemeProvider theme={lightMode}>
									<LandingView mobile={mobile} loginPath={"/login"}/>
								</ThemeProvider>
							} />

							{/* Landing Page (for iOS) */}
							<Route path="/ios" element={
								<ThemeProvider theme={lightMode}>
									<LandingView mobile={mobile} hideAppStoreButton={true} loginPath={"/login-ios"}/>
								</ThemeProvider>
							}/>

							{/* Privacy Policy */}
							<Route path="/privacy-policy.html" />

							{/* Login */}
							<Route path="/login" element={
								<LoginView backButtonPath={"/"}/>
							} />

							{/* Login (for iOS) */}
							<Route path="/login-ios" element={
								<LoginView backButtonPath={"/ios"}/>
							} />

							{/* Login Callback */}
							<Route path="/login/callback" element={
								<LoginCallback setAuthState={setAuthState} />
							} />

							{/* Backdoor */}
							<Route path="/login/backdoor" element={
								<BackdoorLogin />
							} />

							{/* Protected Routes */}
							<Route element={<ProtectedRoute authLoaded={authLoaded} />}>

								{/* Events Routes */}
								<Route element={<EventsProvider mobile={mobile} />}>
									{/* View events in card list */}
									<Route path="/card" element={
										<CardView mobile={mobile} />
									} />

									{/* View events in calendar */}
									<Route path="/calendar" element={
										<CalendarView mobile={mobile} />
									} />

									{/* Mobile-only */}
									{mobile &&
										<>
											{/* View only joined events */}
											<Route path="/joined/:id" element={
												<MyEventsView />
											} />
										</>
									}
								</Route>

								{/* Create account */}
								<Route path="/signup" element={
									<SignupView />
								} />

								{/* View event details */}
								<Route path="/event/:id" element={
									<EventView mobile={mobile} />
								} />

								{/* Desktop-only routes */}
								{!mobile &&
									<>
										{/* View group chats and chat messages */}
										<Route path="/chat" element={
											<ChatView mobile={mobile} />
										} />

										{/* Admin Panel */}
										<Route element={<ProtectedRoute authLoaded={authLoaded} checkAdmin={true} />}>
											<Route path="/admin" element={
												<MetricView />
											} />
										</Route>
									</>
								}

								{/* Mobile-only routes */}
								{mobile &&
									<>
										{/* View group chats */}
										<Route path="/chat" element={
											<GroupChatView />
										} />

										{/* View chat messages */}
										<Route path="/chat/:id" element={
											<ChatView mobile={mobile} />
										} />


										{/* View account details */}
										<Route path="/account" element={
											<AccountView />
										} />
									</>
								}
							</Route>

							{/* Default to login view */}
							<Route path="*" element={
								<Navigate to="/login" />
							} />
						</Routes>
					</AuthContext.Provider>
				</AlertContext.Provider>
			</ColorModeContext.Provider>
			<CssBaseline enableColorScheme />
		</ThemeProvider>
	);
}

export default App;
