import PropTypes from "prop-types";
import { Virtuoso } from "react-virtuoso";
import SendIcon from "@mui/icons-material/Send";
import { IconButton, InputBase, Grid } from "@mui/material";
import { useRef, useState, useCallback, useEffect, isValidElement, useContext } from "react";

// local
import ChatMessage from "./ChatMessage";
import DateMessage from "./DateMessage";
import { getUser } from "../../services/users";
import BroadcastMessage from "./BroadcastMessage";
import AuthContext from "../../contexts/AuthContext";
import { areDatesOnSameDay } from "../../utils/date";
import AlertContext from "../../contexts/AlertContext";
import { createSocket, getMessages } from "../../services/chat";

// constants
const MESSAGES_TO_PREPEND = 20;

// global
let socket;
let username;
let page;
let maxPages;


export default function ChatList(props) {
	const { eventId } = props;

	const [text, setText] = useState("");
	const [messages, setMessages] = useState(() => []);
	const [firstItemIndex, setFirstItemIndex] = useState(null);

	const virtuoso = useRef(null);

	const authContext = useContext(AuthContext);
	const { token, reload } = authContext;

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// effects

	useEffect(() => {
		// reset
		page = 1;
		setMessages(() => []);
		setFirstItemIndex(null);

		socket = createSocket();

		socket.on("connect_error", (error) => {
			setAlert({ message: "Error: Something went wrong. Please try again.", open: true, severity: "error" });
			console.log(error);
		});
	}, [setAlert]);

	useEffect(() => {
		getUser(token)
			.then((res) => {
				const user = res.data.data;
				username = user.username;

				socket.emit("join", eventId);

				getMessages(eventId, page, token)
					.then(res => {
						const { messages, length } = res.data;
						maxPages = Math.ceil(length / 20);
						setFirstItemIndex(0);
						setMessagesInjectDate(messages);
					});
			})
			.catch((err) => {
				console.log(err);
			});
	}, [token, eventId, reload]);

	useEffect(() => {
		socket.on("message", (message) => {
			setMessagesInjectDate([...messages, message]);

			// scroll to end
			virtuoso.current && virtuoso.current.scrollToIndex({
				index: 1000000,
				align: "end",
				behavior: "auto",
			});
		});
	}, [messages]);

	// functions

	function convertToJSX(message) {
		const { contentType, content, sender } = message;
		if (contentType === "text") {
			return <ChatMessage message={message} letter={sender[0]} />;
		} else if (contentType === "broadcast") {
			return <BroadcastMessage content={content} />;
		}
	}

	function sendMessage(text) {
		if (text) {
			socket.emit("sendMessage", {
				event: eventId,
				data: {
					sender: username,
					sent_at: new Date().toISOString(),
					content: text,
					contentType: "text"
				}
			});
		}
	}

	function setMessagesInjectDate(m) {
		if (m && m.length > 0) {
			m = m.filter(({ sent_at }) => { return sent_at; });
			const dates = [...m.map(({ sent_at }) => new Date(sent_at))];

			let datePrior = dates[0];
			m.splice(0, 0, <DateMessage date={dates[0]} />);

			let injected = 1;
			for (let i = 1; i < dates.length; i++) {
				const date = dates[i];
				if (!areDatesOnSameDay(date, datePrior)) {
					m.splice(i + injected, 0, <DateMessage date={dates[i]} />);
					injected = injected + 1;
				}
				datePrior = date;
			}
			setFirstItemIndex(val => val + injected);
		}
		setMessages(() => m);
	}

	const prependItems = useCallback(() => {
		if (page < maxPages) {
			let nextFirstItemIndex = firstItemIndex - MESSAGES_TO_PREPEND;

			// less than 20 previous messages
			if (nextFirstItemIndex < 0) {
				nextFirstItemIndex = 0;
			}

			page = page + 1;
			getMessages(eventId, page, token)
				.then(res => {
					setFirstItemIndex(nextFirstItemIndex);
					setMessagesInjectDate([...res.data.messages, ...messages]);
				});
		}

		return false;
	}, [firstItemIndex, messages, eventId, token]);


	return (
		<Grid container direction="column" sx={{
			height: "100%",
		}}>
			<Grid item xs={true}>
				{firstItemIndex ? 
					<Virtuoso
						ref={virtuoso}
						style={{ height: "100%" }}
						initialTopMostItemIndex={MESSAGES_TO_PREPEND - 1}
						firstItemIndex={firstItemIndex}
						data={messages}
						startReached={prependItems}
						itemContent={(index, message) => {
							return isValidElement(message) ? message : convertToJSX(message);
						}}
					/>
					:
					<></>
				}
			</Grid>

			{/* Messages Bar */}
			<Grid item>
				<Grid container direction="row" sx={{
					p: 1,
					alignItems: "center"
				}}>
					<Grid item xs={true}>
						<InputBase
							sx={{ ml: 1, flex: 1, width: "100%" }}
							multiline={true}
							placeholder="Send message..."
							value={text}
							onChange={(event) => setText(event.target.value)}
							onKeyDown={(ev) => {
								if (ev.key === "Enter") {
									ev.preventDefault();
									sendMessage(text);
									setText("");
								}
							}}
						/>
					</Grid>
					<Grid item>
						<IconButton
							sx={{ width: "100%" }}
							onClick={() => {
								sendMessage(text);
								setText("");
							}}
						>
							<SendIcon color="info"/>
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

ChatList.propTypes = {
	eventId: PropTypes.string.isRequired,
};
