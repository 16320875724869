import { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, AppBar, Toolbar } from "@mui/material";

// local
import AuthContext from "../contexts/AuthContext";
import CardList from "../components/mobile/CardList";
import EventsContext from "../contexts/EventsContext";
import MobileAppBarBottom from "../components/mobile/MobileAppBarBottom";


export default function MyEventsView() {
	// auth
	const authContext = useContext(AuthContext);
	const { user } = authContext;

	// events context
	const eventsContext = useContext(EventsContext);
	let { events, setAddEventModalState } = eventsContext;

	const [joined, setJoined] = useState([]);
    
	useEffect(() => {
		// filter by events joined
		setJoined(events.filter(({ passengers }) => passengers.indexOf(user._id) > -1));
	}, [events, user._id]);


	return (
		<>  
			<Box sx={{ height: "100vh", width: "100%", display: "flex", flexDirection: "column" }}>
				<Box item sx={{ width: "100%" }}>
					<AppBar color="white" position="fixed">
						<Toolbar sx={{ display: "flex", justifyContent: "center" }}>
							<Typography color="inherit" type="title" sx={{ fontWeight: 700, fontSize: 20 }}>
                                My Events
							</Typography>
						</Toolbar>
					</AppBar>
					<Toolbar/>
				</Box>
				<Box sx={{ height: "100%", width: "100%" }}>
					<Grid container direction="column" sx={{
						p: 2, 
						backgroundColor: "#73BFB8",
						height: "100%",
					}}>
						<Grid item xs={true}>
							<CardList events={joined} hideTitle={true} setAddEventModalState={setAddEventModalState}/>
						</Grid>
						<Grid item xs="auto" sx={{
							height: 70,
						}}>
							<MobileAppBarBottom/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}
