import bbox from "@turf/bbox";
import mapboxgl from "!mapbox-gl"; 
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { lineString as makeLineString} from "@turf/helpers";


export default function Map(props) {
	const { events, filter, setFilter } = props;

	// hooks

	// get color mode
	const _theme = useTheme();
	const { mode } = _theme.palette;

	// create references for map objects
	const map = useRef(null);
	const mapContainer = useRef(null);

	// states

	const [theme, setTheme] = useState(mode);
	const [markers, setMarkers] = useState([]);
	const [initialZoom, setInitialZoom] = useState(false);

	// effects

	/* eslint-disable */
    useEffect(() => {
        if (map.current && theme === mode) {
            map.current.resize();
            return; // initialize map only once
        }

        setTheme(mode);
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mode === "light" ? "mapbox://styles/mapbox/streets-v11" : "mapbox://styles/mapbox/dark-v10",
            center: [-76.611916, 39.306472],
        });
        map.current.addControl(new mapboxgl.NavigationControl());
    });
    /* eslint-enable */

	useEffect(() => {
		// remove prior markers
		for (const marker of markers) {
			marker.remove();
		}

		// get list of coordinates
		const coords = [];
		for (const event of events) {
			const location = event.destination.location;
			if (location) {
				coords.push(location);
			}
		}

		// fit map to bounds
		let bounds;
		if (coords.length > 1) {
			bounds = bbox(makeLineString(coords));
		} else if (coords.length === 1) {
			bounds = bbox(makeLineString([coords[0], coords[0]]));
		} else {
			// Baltimore bbox by default
			bounds = bbox(makeLineString([[-76.715943, 39.200397], [-76.523339, 39.372058]]));
		}

		const animationDuration = 2000;
		if (initialZoom) {
			map.current.fitBounds(bounds, { padding: 50, maxZoom: 12, duration: animationDuration });
		} else {
			map.current.fitBounds(bounds, { padding: 50, maxZoom: 12, duration: 0 });
			setTimeout(() => setInitialZoom(true), animationDuration);
		}

		// Create a marker and button 
		const tempMarkers = [];
		for (const event of events) {
			const location = event.destination.location;
			if (!location) continue;

			const lng = location[0];
			const lat = location[1];

			const marker = new mapboxgl.Marker()
				.setLngLat([lng, lat])
				.addTo(map.current);
			tempMarkers.push(marker);

			const el = document.createElement("div");
			el.style.width = 40 + "px";
			el.style.height = 40 + "px";
			el.style.marginTop = -20 + "px";
			el.style.cursor = "pointer";
			const button = new mapboxgl.Marker(el)
				.setLngLat([lng, lat])
				.addTo(map.current);
			tempMarkers.push(button);
			el.addEventListener("click", () => {
				// remove if clicked again
				if (filter === event.destination.location) {
					setFilter(null);
				} else {
					setFilter(event.destination.location);
				}
			});
		}
		setMarkers(tempMarkers);
		// eslint-disable-next-line
    }, [events, theme]);
    
	return (
		<Box ref={mapContainer} className="map-container" sx={{ height: "100%" }}/>
	);
}

Map.propTypes = {
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	filter: PropTypes.string,
	setFilter: PropTypes.func.isRequired,
};
