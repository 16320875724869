import PropTypes from "prop-types";
import { Virtuoso } from "react-virtuoso";
import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";


export default function SelectList(props) {
	const { field, items, selected, setSelected } = props;

	// states
	const [names, setNames] = useState([]);

	// effects
	useEffect(() => {
		setNames(items.map((item) => item[field]));
	}, [items, field]);
    

	return (
		<Virtuoso
			data={names}
			itemContent={(index, name) => {
				return (
					<Box onClick={() => setSelected(index)} sx={{ 
						height: 50,
						p: 2, 
					}}>
						<Box sx={{
							boxShadow: "1",
							height: "40px",
							pl: 2,
							...{ "backgroundColor": index === selected ? "#eee" : undefined }
						}}>
							<Typography sx={{
								lineHeight: "40px",
							}}>
								{name}
							</Typography>
						</Box>
					</Box>
				);
			}}
		/>
	);

}

SelectList.propTypes = {
	field: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	selected: PropTypes.number.isRequired,
	setSelected: PropTypes.func.isRequired,
};
