import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";


export default function BackButton(props) {
	const { path } = props;

	const navigate = useNavigate();


	return (
		<Avatar 
			sx={{ zIndex: 3000, position: "fixed", top: 20, left: 25, boxShadow: 3, bgcolor: "#FFFFFF", color: "black" }} 
			onClick={() => navigate(path)}
		>
			<ClearIcon />
		</Avatar>
	);
}

BackButton.propTypes = {
	path: PropTypes.string.isRequired,
};
