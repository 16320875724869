import PropTypes from "prop-types";
import { List, Typography, ListItem } from "@mui/material";

// local
import FilterItems from "../reusable/FilterItems";


export default function FiltersMenu(props) {
	const { setFilters } = props;

	return (
		<>
			<List sx={{ height: "100%" }}>
				<ListItem>
					<Typography sx={{
						fontSize: 30,
						fontWeight: "bold",
					}}>
                        Filters
					</Typography>
				</ListItem>
				<FilterItems setFilters={setFilters} />
			</List>
		</>
	);
}

FiltersMenu.propTypes = {
	setFilters: PropTypes.func.isRequired,
};
