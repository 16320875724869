import { useContext } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { CopyToClipboard } from "react-copy-to-clipboard";

// local
import AlertContext from "../../contexts/AlertContext";


export default function ShareButton(props) {
	const { copy, sx, size } = props;

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	return (
		<IconButton sx={sx}>
			<CopyToClipboard text={copy} onCopy={() => setAlert({ message: "Link copied", open: true, severity: "info" })}>
				{/* <Avatar sx={sx}> */}
				<ShareIcon size={size} color="inherit"/>
				{/* </Avatar> */}
			</CopyToClipboard>
		</IconButton>
	);
}

ShareButton.propTypes =  {
	copy: PropTypes.string.isRequired,
	sx: PropTypes.any,
	size: PropTypes.string,
};
