import { useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Grid, Typography, useTheme, Button, IconButton } from "@mui/material";

// local
import logo_png from "../../assets/logo_white.png";
import AccountModal from "../desktop/AccountModal";


export default function Header() {
	// hooks

	const navigate = useNavigate();

	// get color mode
	const theme = useTheme();
	const { mode } = theme.palette;

	// states
	const [accountModalOpen, setAccountModalOpen] = useState(false);


	return (
		<>
			<AccountModal 
				open={accountModalOpen}
				setOpen={setAccountModalOpen}
			/>
			<Box sx={{
				height: 61,
			}}>
				<Grid container sx={{
					height: 61,
					width: "100%",
					top: 0,
					left: 0,
					position: "absolute",
					backgroundColor: mode === "light" ? "secondary.main" : "primary.light",
					direction: "row",
					alignItems: "center",
				}}>
					<Grid item> 
						<Button 
							onClick={() => navigate("/card")}
							sx={{ padding: 0 }}>
							<img 
								src={logo_png} 
								alt="Split.it Logo" 
								style={{
									height: 61,
									width: 150,
								}}
							/>
						</Button>
					</Grid>
					<Grid item sx={{
						display: "flex",
						alignItems: "flex-end",
						height: "100%",
						pb: "10px",
					}}>
						<Typography color={"#ffffff"}>
                            v1.0
						</Typography>
					</Grid>
					<Grid item xs={true}>
						<Box/>
					</Grid>
					<Grid item sx={{
						display: "flex",
						alignItems: "center",
					}}>
						<IconButton size="large" onClick={() => {
							navigate("/card");
						}}>
							<HomeIcon fontSize="inherit" sx={{ 
								color: "White",
								transform: "scale(1.2)"
							}}/>
						</IconButton>
					</Grid>
					<Grid item sx={{
						display: "flex",
						alignItems: "center",
						mr: 1,
					}}>
						<IconButton size="large" onClick={() => {
							navigate("/chat");
						}}>
							<ChatIcon fontSize="inherit" sx={{ 
								color: "White",
								transform: "scale(1.2)"
							}}/>
						</IconButton>
					</Grid>
					<Grid item sx={{ mr: 2 }}>
						<IconButton size="large" onClick={() => setAccountModalOpen(true)}>
							<AccountCircleIcon fontSize="inherit" sx={{ 
								color: "White",
								transform: "scale(1.5)"
							}}/>
						</IconButton>
					</Grid>
				</Grid>
			</Box>

		</>
	);
}

Header.propTypes = {};
