import moment from "moment";
import { TimePicker } from "antd";
import PropTypes from "prop-types";
import DatePicker from "@mui/lab/DatePicker";
import { Typography, Tooltip, Box, List, Stack, ListItem, TextField } from "@mui/material";

// local
import PlacesAutocomplete from "../reusable/PlacesAutocomplete";

// constants
const FORMAT = "HH:mm";


export default function Add(props) {
	const { eventDetails, setEventDetails, errorState } = props;


	return (
		<List style={{ pt: 2, maxHeight: "100%", overflow: "scroll" }} >
			<ListItem>
				<Stack spacing={2} sx={{ pl: 2, pt: 2, pr: 2, width: "100%" }}>
					<Typography sx={{ pl: 0.5, color: "primary.main", fontSize: 20, fontWeight: 800 }}>Title</Typography>
					<Box display="flex" alignItems="center" justifyContent="center">
						<TextField
							label="Event Title"
							variant="outlined"
							sx={{ width: "95%" }}
							value={eventDetails.title || ""}
							onChange={(event) => {
								setEventDetails({ ...eventDetails, title: event.target.value });
							}}
						/>

					</Box>
				</Stack>
			</ListItem>

			<ListItem>
				<Stack spacing={2} sx={{ pl: 2, pr: 2, width: "100%" }}>
					<Typography sx={{ pl: 0.5, color: "primary.main", fontSize: 20, fontWeight: 800 }}>Location</Typography>
					<Box sx={{ pl: 1, pr: 1 }}>
						<PlacesAutocomplete
							label="Pickup Location"
							value={eventDetails.departure.value || null}
							setValue={(dict) => {
								const { departure } = eventDetails;
								setEventDetails({
									...eventDetails, departure: {
										...departure,
										name: dict && dict.text,
										value: dict,
									}
								});
							}}
							inputValue={eventDetails.departure.name || ""}
							setInputValue={(value) => {
								const { departure } = eventDetails;
								setEventDetails({
									...eventDetails, departure: {
										...departure,
										name: value,
										value: null,
									}
								});
							}}
							variant="filled"
						/>
					</Box>
					<Box sx={{ pl: 1, pr: 1 }}>
						<PlacesAutocomplete
							label="Destination"
							value={eventDetails.destination.value || null}
							setValue={(dict) => {
								const { destination } = eventDetails;
								setEventDetails({
									...eventDetails, destination: {
										...destination,
										name: dict && dict.text,
										value: dict,
									}
								});
							}}
							inputValue={eventDetails.destination.name || ""}
							setInputValue={(value) => {
								const { destination } = eventDetails;
								setEventDetails({
									...eventDetails, destination: {
										...destination,
										name: value,
										value: null,
									}
								});
							}}
						/>
					</Box>
				</Stack>
			</ListItem>

			<ListItem>
				<Box sx={{ 
					ml: 2, 
					mr: 3,
					mt: 1,
					display: "flex",
					alignItems: "center"
				}}>
					<Typography sx={{ pl: 0.5, pr: 3, color: "primary.main", fontSize: 20, fontWeight: 800 }}>Capacity</Typography>
					<Tooltip title={errorState.capacity ?
						"Capacity must be between 2 and 8"
						:
						""}
					>
						<TextField
							sx={{ width: "100%" }}
							error={errorState.capacity}
							label="Capacity"
							value={eventDetails.capacity || ""}
							onChange={(event) => setEventDetails(state => ({
								...state,
								capacity: event.target.value,
							}))}
							InputProps={{
								"type": "number",
							}}
						/>
					</Tooltip>
				</Box>
			</ListItem>

			<ListItem>
				<Stack spacing={2} sx={{ pl: 2, pt: 1, pr: 2, width: "100%" }}>
					<Typography sx={{ pl: 0.5, color: "primary.main", fontSize: 20, fontWeight: 800 }}>Date and Time</Typography>
					<Box sx={{ pl: 1, pr: 1 }}>
						<Stack 
							spacing={2} 
							sx={{
								display: "flex",
								justifyContent: "left",
							}}
						>
							<DatePicker
								minDate={new Date()}
								label="Date"
								openTo="day"
								views={["year", "month", "day"]}
								value={eventDetails.date || null}
								onChange={(value) => { setEventDetails({ ...eventDetails, date: value }); }}
								renderInput={(params) => <TextField {...params} />}
							/>
							<TimePicker
								className="timepicker-styles"
								size="large"
								minuteStep={5}
								format={FORMAT}
								placeholder="Time"
								style={{ width: "100%", height: 56, fontSize: 20, borderRadius: "4px", background: "rgb(0,0,0,0)" }}
								getPopupContainer={triggerNode => triggerNode.parentNode}
								value={eventDetails.time ? moment(eventDetails.time) : undefined}
								onChange={(value) => { setEventDetails({ ...eventDetails, time: value && value._d }); }}
							/>
						</Stack>
					</Box>
				</Stack>
			</ListItem>

			<ListItem>
				<Stack spacing={2} sx={{ pl: 2, pr: 2, width: "100%" }}>
					<Typography sx={{ pl: 0.5, color: "primary.main", fontSize: 20, fontWeight: 800 }}>Note</Typography>
					<Box sx={{ pl: 1, pr: 1 }}>
						<TextField label="Write your note" variant="filled"
							value={eventDetails["notes"]} onChange={(event) => { setEventDetails({ ...eventDetails, notes: event.target.value }); }} sx={{ width: "100%" }} multiline rows={4} />
					</Box>
				</Stack>
			</ListItem>

		</List>
	);
}

Add.propTypes = {
	eventDetails: PropTypes.object.isRequired,
	setEventDetails: PropTypes.func.isRequired,
	errorState: PropTypes.object.isRequired,
};
