import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


export default function AlertDialog(props) {
	const { open, setOpen, handleDropEvent } = props;

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Are you sure you want to drop the event?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You will be penalized if you are dropping the event later than 5 days prior to the trip.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>No</Button>
					<Button onClick={() => { setOpen(false); handleDropEvent(); }} autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

AlertDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	handleDropEvent: PropTypes.func.isRequired,
};
