import PropTypes from "prop-types";
import { ListItem, Typography } from "@mui/material";


export default function DateMessage(props) {
	const { date } = props;


	return (
		<ListItem>
			<Typography sx={{
				width: "100%",
				textAlign: "center",
				fontSize: 12,
			}}>
				{date.toLocaleString().split(",")[0]}
			</Typography>
		</ListItem>
	);
}

DateMessage.propTypes = {
	date: PropTypes.any.isRequired,
};
