import { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Modal } from "@mui/material";

// local
import EventCard from "../reusable/EventCard";
import AuthContext from "../../contexts/AuthContext";


export default function EventModal(props) {
	const { open, setOpen, event, setReload, setAddEventModalState } = props;

	// get auth token and user
	const authContext = useContext(AuthContext);
	const { token, user } = authContext;


	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
		>
			<Box container sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				backgroundColor: "rgb(0,0,0,0)",
			}}>
				<EventCard
					mobile={false}
					event={event}
					id={user._id}
					token={token}
					setReload={setReload}
					defaultExpanded={true}
					hideExpand={true}
					setAddEventModalState={setAddEventModalState}
				/>
			</Box>
		</Modal>
	);
}

EventModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	event: PropTypes.object,
	setReload: PropTypes.func,
	setAddEventModalState: PropTypes.func.isRequired,
};
