import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { VirtuosoGrid } from "react-virtuoso";

// custom list container
const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;


export default function MetricList(props) {
	const { metrics } = props;


	return (
		<VirtuosoGrid
			style={{ height: "100%" }}
			components={{
				List: ListContainer,
			}}
			totalCount={metrics.length}
			itemContent={index => metrics[index]}
		/>
	);
}

MetricList.propTypes = {
	metrics: PropTypes.array.isRequired,
};
