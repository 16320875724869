
function PrefilledLocations() {
	return (
		[{
			text: "Charles Commons",
			place_name: "Charles Commons, 3301 N Charles St, Baltimore, MD 21218",
			center: [-76.61851388470778, 39.32830437950649],
		},
		{
			text: "Power Plant",
			place_name: "Power Plant, 34 Market Pl, Baltimore, MD 21202",
			center: [-76.60957798255615, 39.28911459999999],
		},
		{
			text: "H Mart",
			place_name: "H Mart, 800 N Rolling Rd, Catonsville, MD 21228",
			center: [-76.6678957790456, 39.317573387068485],
		},
		{
			text: "GreatWall Supermarket",
			place_name: "GreatWall Supermarket, 5510 Baltimore National Pike, Catonsville, MD 21228",
			center: [-76.70582592146987, 39.309243904208195],
		},
		{
			text: "Carey Business School",
			place_name: "Carey Business School, 100 International Drive, Baltimore, MD 21202",
			center: [-76.60176857872602, 39.28263580191736],
		},
		{
			text: "BWI Marshall Airport",
			place_name: "BWI, 7050 Friendship Rd, Baltimore, MD 21240",
			center: [-76.66869697503218, 39.179653262961935],
		},
		{
			text: "Penn Station Baltimore",
			place_name: "Penn Station, 1500 N Charles St, Baltimore, MD 21201",
			center: [-76.61586074619169, 39.307559812921625],
		},
		{
			text: "Giant Food - Waverly",
			place_name: "Giant Food, 601 E 33rd St, Baltimore, MD 21218",
			center: [-76.60780801742567, 39.32806495218935],
		},
		{
			text: "Giant Food - Hoes Heights",
			place_name: "Giant Food, 1020 W 41st St, Baltimore, MD 21211",
			center: [-76.6355286627927, 39.339168214642264],
		},
		{
			text: "Ronald Reagan Washington National Airport (DCA)",
			place_name: "DCA, 2401 Smith Blvd, Arlington, VA 22202",
			center: [-77.04018858667588, 38.85143415290516],
		},
		{
			text: "National Aquarium",
			place_name: "National Aquarium, 501 E Pratt St, Baltimore, MD 21202",
			center: [-76.60837694433609, 39.28550073320751],
		},
		{
			text: "The Academy on Charles",
			place_name: "The Academy, 3700 N Charles St, Baltimore, MD 21218",
			center: [-76.61848837502758, 39.334127251691974],
		},
		{
			text: "Nine East 33rd",
			place_name: "Nine East, 9 E 33rd St, Baltimore, MD 21218",
			center: [-76.61656289184756, 39.32807694553056],
		}]
	);
}

export default PrefilledLocations;
