import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router";

// local
import { verifyToken } from "../services/auth";
import { getUser } from "../services/users";
import AuthContext from "../contexts/AuthContext";
import AlertContext from "../contexts/AlertContext";

// env
const DEMO = process.env.REACT_APP_DEMO;


export default function ProtectedRoute(props) {
	const { authLoaded, checkAdmin } = props;

	// hooks
	const { pathname } = useLocation();
	const navigate = useNavigate();

	// contexts

	// auth
	const authContext = useContext(AuthContext);
	const { user, token } = authContext;

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// states
	const [authenticated, setAuthenticated] = useState(undefined);

	// effects

	useEffect(() => {
		if (DEMO !== "false" && !checkAdmin) {
			setAuthenticated(true);
		} else if (authLoaded) {
			if (user) {
				if (checkAdmin) {
					getUser(token)
						.then(res => {
							const user = res.data.data;
							const { isAdmin } = user;
							setAuthenticated(isAdmin);
						})
						.catch(err => {
							setAlert({ message: "Error: Invalid credentials", open: true, severity: "error" });
							console.log(err);
							invalidLogin();
						});
				} else {
					verifyToken(token)
						.then(res => {
							setAuthenticated(!!res.data.token);
						})
						.catch(err => {
							setAlert({ message: "Error: You are not authenticated", open: true, severity: "error" });
							console.log(err);
							invalidLogin();
						});
				}
			} else {
				invalidLogin();
			}
		}
		// eslint-disable-next-line
    }, [user, token, authLoaded]);

	// functions

	function invalidLogin() {
		localStorage.setItem("destinationPath", pathname);

		const loginPath = localStorage.getItem("loginPath");
		navigate(loginPath || "/login");
	}

	if (authenticated !== undefined) {
		if (!user || !authenticated) {
			const loginPath = localStorage.getItem("loginPath");
			navigate(loginPath || "/login");
		} else if (!user._id && pathname !== "/signup") {
			navigate("signup");
		}
		return <Outlet/>;
	}

	return (
		<Box sx={{
			position: "absolute",
			transform: "translate(-50%, -50%)",
			left: "50%",
			top: "50%",
		}}>
			<CircularProgress color="primary" /> 
		</Box>
	);
}

ProtectedRoute.propTypes = {
	authLoaded: PropTypes.bool.isRequired,
	checkAdmin: PropTypes.bool,
};
