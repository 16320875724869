import PropTypes from "prop-types";
import { FormControl, FormLabel, Select, MenuItem, Stack } from "@mui/material";


export default function RadioButtonsGroup(props) {
	const { value, setValue } = props;

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return (
		<Stack sx={{
			width: "150px",
		}}>
			<FormControl>
				<FormLabel focused={false} id="demo-radio-buttons-group-label" sx={{ display: "inline" }}> Sort By</FormLabel>
				<Select
					labelId="sort-select-label"
					id="sort-select"
					value={value}
					label="Sort by..."
					onChange={handleChange}
					size="small"
				>
					<MenuItem value={"date"}>Date</MenuItem>
					<MenuItem value={"passengers"}>Passengers</MenuItem>
				</Select>
			</FormControl>
		</Stack>
	);
}

RadioButtonsGroup.propTypes = {
	value: PropTypes.any.isRequired,
	setValue: PropTypes.func.isRequired,
};
