import { useState } from "react";
import { Grid, Box, Toolbar } from "@mui/material";

// local
import Title from "../components/mobile/Title";
import GroupChatsList from "../components/reusable/GroupChatsList";
import MobileAppBarBottom from "../components/mobile/MobileAppBarBottom";


export default function GroupChatView() {
	const [reload, setReload] = useState(true);

	return (
		<>
			<Grid container direction="column" sx={{ height: "100vh" }}>
				<Grid item>
					<Title text={"Chat"} hideBack={true}/>
				</Grid>
				<Grid item>
					<Toolbar/>
				</Grid>
				<Grid item xs={true}>
					<Box sx={{ pt: 1, height: "100%" }}>
						<GroupChatsList mobile={true} setEvent={() => {}} reload={reload} setReload={setReload}/>
					</Box>
				</Grid>
				<Grid item sx={{ height: 70 }}>
					<MobileAppBarBottom/>
				</Grid>

			</Grid>
		</>
	);
}
