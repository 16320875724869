import jwt from "jwt-decode"; 
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

// local
import AuthContext from "../contexts/AuthContext";


export default function LoginCallback(props) {
	const { setAuthState } = props;

	// hooks

	const navigate = useNavigate();

	const { search } = useLocation();
	const authContext = useContext(AuthContext);

	// effects

	useEffect(() => {
		const { login } = authContext;
		const params = new URLSearchParams(search);
		const res = JSON.parse(decodeURIComponent(params.get("data")));

		if (!res) {
			const loginPath = localStorage.getItem("loginPath");
			navigate(loginPath || "/login");
		} else {
			// get token from query
			const { token } = res;

			// get expiration from token
			const decoded = jwt(token);
			const { exp, sub, jhed, email } = decoded;

			// create new date from exp
			const expirationDate = new Date(0);
			expirationDate.setUTCSeconds(exp);

			// if user doesn"t already exist
			if (!sub) {
				login(token, expirationDate, { jhed: jhed, email: email });
				// finish registration at signup
				navigate("/signup");

				// if user does exist
			} else {
				login(token, expirationDate, { _id: sub });

				// send to card view or destination path
				const destinationPath = localStorage.getItem("destinationPath");
				if (destinationPath) {
					localStorage.removeItem("destinationPath");
					navigate(destinationPath);
				} else {
					navigate("/card"); 
				}
			}
		}
	}, [authContext, navigate, search, setAuthState]);

	// empty return stub
	return (<></>);
}

LoginCallback.propTypes = {
	setAuthState: PropTypes.func.isRequired,
};
