import PropTypes from "prop-types";
import FlagIcon from "@mui/icons-material/Flag";
import HailIcon from "@mui/icons-material/Hail";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";

// local
import PassengerList from "./PassengerList";
import Map from "../../components/mobile/Map";
import { convertDay, convertMonth } from "../../utils/date";


export default function EventScroll(props) {
	const { event } = props;

	let start_time;
	let start_year;
	let start_month;
	let start_date_m;
	let start_day;
	if (event) {
		start_time = event.start.toLocaleTimeString("en-US");
		start_year = event.start.getFullYear();
		start_month = convertMonth[event.start.getMonth()];
		start_date_m = event.start.getDate();
		start_day = convertDay[event.start.getDay()];
	}

	return (
		<>
			<Grid container direction="column" sx={{
				height: "100%",
				width: "100%",
			}}>
				<Grid item xs={5} sx={{
					width: "100%"
				}}>
					<Map event={event} />
				</Grid>
				<Grid item xs={7} sx={{
					width: "100%",
					overflow: "scroll",
				}}>
					<Box sx={{
						mt: 3,
						mx: 3,
					}}>
						<Typography sx={{
							fontFamily: "Muli",
							fontSize: 30,
							fontWeight: 700,
							m: 0.5
						}}
						>
							{event.title}
						</Typography>
					</Box>
					<List sx={{ pt: 1 }}>
						<ListItem>
							<Box sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "flex-start"
							}}>
								<EventIcon sx={{
									ml: 2,
									mr: 2,
									mt: 0.3
								}}
								/>
								<Box sx={{
									display: "flex",
									flexDirection: "column"
								}}
								>
									<Typography sx={{
										fontFamily: "Muli",
										fontSize: 15,
										fontWeight: 700
									}}
									>
										{start_day}, {start_month} {start_date_m}, {start_year}
									</Typography>
									<Typography sx={{
										fontFamily: "Muli",
										fontSize: 13,
										fontWeight: 500
									}}
									>
										{start_time}
									</Typography>
								</Box>
							</Box>
						</ListItem>
						<ListItem>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
								<LocationOnIcon sx={{ ml: 2, mr: 2, mt: 0.3 }} />
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Typography sx={{ fontFamily: "Muli", fontSize: 15, fontWeight: 700 }}>{event.destination.name}</Typography>
									<Typography sx={{ fontFamily: "Muli", fontSize: 13, fontWeight: 500 }}>{event.destination.address}</Typography>
								</Box>
							</Box>
						</ListItem>
						<ListItem>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
								<FlagIcon sx={{ ml: 2, mr: 2, mt: 0.3 }} />
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Typography sx={{ fontFamily: "Muli", fontSize: 15, fontWeight: 700 }}>{event.departure.name}</Typography>
									<Typography sx={{ fontFamily: "Muli", fontSize: 13, fontWeight: 500 }}>{event.departure.address}</Typography>
								</Box>
							</Box>
						</ListItem>
						{event.names &&
							<ListItem component="div">
								<Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
									<HailIcon sx={{ ml: 2, mr: 2, mt: 0.3 }} />
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<PassengerList names={event.names} passengers={event.passengers} username={"test"} />
									</Box>
								</Box>
							</ListItem>
						}
						{event.notes &&
							<ListItem>
								<Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
									<SpeakerNotesIcon sx={{ ml: 2, mr: 2, mt: 0.3 }} />
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<Typography sx={{ fontFamily: "Muli", fontSize: 15, fontWeight: 700 }}>About</Typography>
										<Typography sx={{ fontFamily: "Muli", fontSize: 13, fontWeight: 500 }}>{event.notes} </Typography>
									</Box>
								</Box>
							</ListItem>
						}
					</List>

				</Grid>
			</Grid>
		</>
	);
}

EventScroll.propTypes = {
	event: PropTypes.object.isRequired,
};
