/* eslint-disable indent */
import PropTypes from "prop-types";
import { useTheme } from "@mui/system";
import { useContext, useState, useEffect } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { Button, TextField, Box, Grid, IconButton, CircularProgress, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";

// local
import AuthContext from "../../contexts/AuthContext";
import AlertContext from "../../contexts/AlertContext";
import { getUser, modifyUser } from "../../services/users";
import ColorModeContext from "../../contexts/ColorModeContext";


export default function AccountInfo(props) {
	const { hideColorMode } = props;

	// hooks

	const theme = useTheme();
	const colorMode = useContext(ColorModeContext);

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// auth
	const authContext = useContext(AuthContext);
	const { logout, token, reload } = authContext;

	// states

	const [editing, setEditing] = useState(false);
	const [emailOn, setEmailOn] = useState(false);
	const [loading, setLoading] = useState(false);

	// manage username from database and while editing
	const [username, setUsername] = useState({
		db: null,
		temp: null,
	});
	const [venmo, setVenmo] = useState({
		db: null,
		temp: null,
	});
	const [referralToken, setReferralToken] = useState("");

	// effects

	useEffect(() => {
		setLoading(true);
		getUser(token)
			.then((res) => {
				const _user = res.data.data;
				setUsername({ db: _user.username, temp: _user.username });
				setVenmo({ db: _user.venmo, temp: _user.venmo });
				setEmailOn(_user.subscribed);
				setReferralToken(_user.referralToken);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setLoading(false));
	}, [token]);

	async function handleModifyUser() {
		setLoading(true);
		modifyUser(username.temp, token, undefined, venmo.temp)
			.then(() => {
				setUsername(state => ({ ...state, db: state.temp }));
				setVenmo(state => ({ ...state, db: state.temp }));
				reload();
			})
			.catch((err) => {
				let message = "Error: Something went wrong. Please try again.";
				if (err.response.status === 403) {
					message = err.response.data.message;
				}
				setAlert({ open: true, message: message, severity: "error" });
				console.log(err);
			})
			.finally(() => setLoading(false));
	}

	const handleEmailOn = (event, val) => {
		if ((event.currentTarget.value === "true") !== emailOn) {
			setLoading(true);
			modifyUser(undefined, token, val)
				.then(() => {
					setEmailOn(val);
				})
				.catch((err) => {
					setAlert({ open: true, message: "Error: Something went wrong. Please try again.", severity: "error" });
					console.log(err);
				})
				.finally(() => setLoading(false));
		}
	};


	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Grid item sx={{
				display: "flex",
				justifyContent: "center",
			}}>
				<AccountCircleIcon fontSize="large" sx={{
					transform: "scale(1.5)",
				}} />
			</Grid>
			<Grid item sx={{
				width: "100%",
			}}>
				<Grid container sx={{
					justifyContent: "center",
				}}>
					{loading ?
						<Box sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}>
							<CircularProgress color="primary" />
						</Box>
						:
						<>
							<Grid item sx={{
								display: "flex",
								justifyItems: "center",
							}}>
								{editing ?
									<>
										<Grid container direction="column" sx={{
											p: 1,
											gap: 1,
										}}>
											<Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
												<TextField
													label="username"
													sx={{ width: "100%" }}
													value={username.temp}
													onChange={(event) => setUsername(state => ({ ...state, temp: event.target.value }))}
												/>
											</Grid>
											<Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
												<TextField
													label="venmo"
													sx={{ width: "100%" }}
													value={venmo.temp}
													onChange={(event) => setVenmo(state => ({ ...state, temp: event.target.value }))}
												/>
											</Grid>
											<Grid item sx={{
												display: "flex",
												justifyContent: "center"
											}}>
												<Button
													variant="text"
													onClick={() => {
														handleModifyUser();
														setEditing(!editing);
													}}
												>
													Save
												</Button>
												<Button
													variant="text"
													onClick={() => {
														setEditing(!editing);
													}}
												>
													Cancel
												</Button>
											</Grid>
										</Grid>
									</>
									:
									<>
										<Grid container direction="column">
											<Grid item>
												<Typography sx={{
													width: "100%",
													textAlign: "center",
												}}>
													{username.db}
												</Typography>
											</Grid>
											<Grid item>
												<Button
													variant="text"
													onClick={() => {
														setEditing(!editing);
													}}
												>
													Edit Profile
												</Button>
											</Grid>
										</Grid>
									</>
								}
							</Grid>
							<Grid item sx={{ width: "100%" }}>
								<Grid container direction="row" spacing={2} sx={{
									alignItems: "center",
									justifyContent: "center",
								}}>
									<Grid item>
										<Typography sx={{
										}}>
											Email Preferences
										</Typography>
									</Grid>
									<Grid item>
										<ToggleButtonGroup
											value={emailOn}
											exclusive
											onChange={handleEmailOn}
										>
											<ToggleButton value={true}>
												<NotificationsIcon />
											</ToggleButton>
											<ToggleButton value={false}>
												<NotificationsOffIcon />
											</ToggleButton>
										</ToggleButtonGroup>
									</Grid>
								</Grid>
							</Grid>
						</>
					}
				</Grid>
			</Grid>
			{!hideColorMode &&
				<Grid item>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{theme.palette.mode[0].toUpperCase() + theme.palette.mode.slice(1)} Mode
						<IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
							{theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
						</IconButton>
					</Box>
				</Grid>
			}
			<Grid item>
				<Typography sx={{ fontSize: 12, textAlign: "center" }}>
					Referral Token: {referralToken}
				</Typography>
			</Grid>
			<Grid item>
				<Typography sx={{ fontSize: 12, textAlign: "center" }}>
					Found a bug?  Help us out <a href="https://docs.google.com/forms/d/e/1FAIpQLSeSsTwRvfFQdRtpteHwV2PTd5zgakAeGb8MbGBsGfKqKLxCFg/viewform?usp=sf_link" target="_blank" rel="noreferrer">here</a>
				</Typography>
			</Grid>
			<Grid item sx={{
				display: "flex",
				justifyContent: "center",
			}}>
				<Button variant="contained" onClick={() => {
					logout();
				}}>
					Logout
				</Button>
			</Grid>
		</Grid>
	);
}

AccountInfo.propTypes = {
	hideColorMode: PropTypes.bool,
};
