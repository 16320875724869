/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Button, TextField, Grid, Box, Typography, Container, Tooltip } from "@mui/material";

// local
import { createUser } from "../services/users";
import AuthContext from "../contexts/AuthContext";
import AlertContext from "../contexts/AlertContext";



function SignupView() {
	const [venmo, setVenmo] = useState("");
	const [username, setUsername] = useState("");
	const [referralToken, setReferralToken] = useState("");

	const authContext = useContext(AuthContext);
	const { user, token } = authContext;
	const { jhed, email } = user;

	const navigate = useNavigate();

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	async function handleSubmit(event) {
		event.preventDefault();
		try {
			await createUser(username, jhed, email, venmo, token, referralToken);
			setAlert({ open: true, message: "Account created! Please login to see events", severity: "success" });

			const loginPath = localStorage.getItem("loginPath");
			navigate(loginPath || "/login");
		} catch (error) {
			let message = "Error: Something went wrong. Please try again.";
			if (error.response.status === 403) {
				message = error.response.data.message;
			}
			setAlert({ open: true, message: message, severity: "error" });
			console.log(error);
		}
	}

	return (
		<Container component="main" maxWidth="xs">
			<Box
				sx={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography sx={{
					fontSize: 36,
				}}>
                    Welcome!
				</Typography>
				<Typography sx={{
					fontSize: 20,
				}}>
                    Finish Creating Your Account
				</Typography>
				<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
					<Grid container spacing={2}>
						<Grid item>
							<Tooltip title="Make this whatever you want (it can even be anonymous)">
								<TextField
									required
									sx={{ width: "100%" }}
									label="Username"
									autoComplete="username"
									value={username}
									onChange={(event) => setUsername(event.target.value)}
								/>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title="Add your venmo username - if you have one - to enable venmo integration">
								<TextField
									sx={{ width: "100%" }}
									label="Venmo Username"
									value={venmo}
									onChange={(event) => setVenmo(event.target.value)}
								/>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title="If someone else invited you to make an account, help them out by adding their referral token here!">
								<TextField
									sx={{ width: "100%" }}
									label="Referral Token"
									value={referralToken}
									onChange={(event) => setReferralToken(event.target.value)}
								/>
							</Tooltip>
						</Grid>
					</Grid>
					<Button
						variant="contained"
						sx={{ mt: 3, mb: 2, width: "100%" }}
						onClick={handleSubmit}
					>
                        Sign Up
					</Button>
				</Box>
			</Box>
		</Container>
	);
}

export default SignupView;
