import { createContext } from "react";

// context passed to all routes
const AuthContext = createContext({
	user: null,
	token: null,
	login: () => {},
	logout: () => {},
	reload: () => {},
});

export default AuthContext;
