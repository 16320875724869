import axios from "axios";


const BASE_URL = process.env.REACT_APP_BACKEND_URL;

/**
 * Gets list of all events.
 * @returns list of properly formatted events
 */
async function getAllEvents(filters, token) {
	let { dateRange, departure, destination, notes, joined } = filters;

	let startRange, endRange;
	if (dateRange) {
		startRange = dateRange[0];
		endRange = dateRange[1];
		if (startRange) {
			const now = new Date();
			if (startRange > now) {
				startRange = new Date(startRange);
				startRange.setHours(0);
				startRange.setMinutes(0);
			} else {
				// set start to one minute from now
				startRange = new Date(now.getTime() + 1000 * 60);
			}
		}
		if (endRange) {
			endRange = new Date(endRange);
			endRange.setHours(23);
			endRange.setMinutes(59);
		}
	}

	const response = await axios.get(`${BASE_URL}/api/events`, { 
		params: {
			start: startRange,
			end: endRange,
			departure: departure,
			destination: destination,
			notes: notes,
			joined: joined || undefined,
		},
		headers: { Authorization: `Bearer ${token}` },
	});

	const events = response.data.data;
	return events.map(_map);
}

/**
 * Gets singular event.
 * @param {_id} eventID 
 * @returns singular event id
 */
async function getEvent(eventID, token) {
	const response = await axios.get(`${BASE_URL}/api/events/${eventID}`, {
		headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
	});

	const event = response.data.data;
	return _map(event);
}

async function createEvent(params, token) {
	const { date, departure, destination, owner, notes, capacity, title } = params;
	const res = await axios.post(`${BASE_URL}/api/events`, {
		date: date,
		departure: departure,
		destination: destination,
		owner: owner,
		notes: notes,
		capacity: capacity,
		title: title,
	}, {
		headers: { Authorization: `Bearer ${token}` },
	});
	return res;
}

async function editEvent(params, token) {
	let { userID, eventID, newEvent } = params;
	await axios.put(`${BASE_URL}/api/events/${eventID}`, {
		user: userID,
		date: newEvent.date,
		departure: newEvent.departure,
		destination: newEvent.destination,
		owner: newEvent.owner,
		notes: newEvent.notes,
		capacity: newEvent.capacity,
		title: newEvent.title,
	}, {
		headers: {Authorization: `Bearer ${token}`}
	});
}

async function uploadRideDetails(file, total, eventId, token) {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("total", total);
	return axios.post(`${BASE_URL}/api/receipt/${eventId}`, 
		formData, 
		{
			headers: {
				"Authorization": `Bearer ${token}`,
				"Content-Type": "multipart/form-data"
			}
		},
	);
}

async function clearRideDetails(eventId, token) {
	return axios.put(`${BASE_URL}/api/receipt/${eventId}`, 
		{},
		{
			headers: {
				"Authorization": `Bearer ${token}`,
			}
		},
	);
}

async function getRideDetails(eventId, token) {
	return axios.get(`${BASE_URL}/api/receipt/${eventId}`, 
		{
			headers: {
				"Authorization": `Bearer ${token}`,
			}
		},
	);
}

function _map(event) {
	let start = new Date(event.date);
	let end = new Date(start);
	end.setHours(end.getHours() + 1);

	if (start.getDate() !== end.getDate()) {
		end.setFullYear(start.getFullYear());
		end.setMonth(start.getMonth());
		end.setDate(start.getDate());
		end.setHours(start.getHours());
		end.setMinutes(59);
		end.setSeconds(0);
	}

	return {
		...event,
		start: start,
		end: end,
		numPassengers: event.passengers.length,
		passengers: event.passengers.map(({ _id }) => _id),
	};
}

export { getAllEvents, getEvent, createEvent, editEvent, uploadRideDetails, getRideDetails, clearRideDetails };
