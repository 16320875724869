import axios from "axios";
import io from "socket.io-client";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

function createSocket() {
	return io(BASE_URL);
}

async function getFirstMessage(eventId, token) {
	const res = await axios.get(`${BASE_URL}/api/messages/${eventId}`, { 
		headers: { Authorization: `Bearer ${token}` },
	});
	res.data.messages = res.data.messages.map(message => {
		return {
			...message,
			sent_at: new Date(message.sent_at)
		};
	});
	return res;
}

async function getMessages(eventId, page, token) {
	const res = await axios.get(`${BASE_URL}/api/messages/${eventId}`, { 
		params: {
			page: page,
		},
		headers: { Authorization: `Bearer ${token}` },
	});
	res.data.messages = res.data.messages.map(message => {
		return {
			...message,
			sent_at: new Date(message.sent_at)
		};
	});
	return res;
}

// deprecated in favor of server side creation
async function createMessageGroup(id, token) {
	return axios.post(`${BASE_URL}/api/messages/`, { 
		event: id,
	}, {
		headers: { Authorization: `Bearer ${token}` },
	},
	);
}

export { createSocket, getFirstMessage, getMessages, createMessageGroup };
