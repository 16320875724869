import PropTypes from "prop-types";
import { Virtuoso } from "react-virtuoso";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useTheme, Box, CircularProgress } from "@mui/material";

// local
import GroupChat from "./GroupChat";
import { getAllEvents } from "../../services/events";
import AuthContext from "../../contexts/AuthContext";
import { getFirstMessage } from "../../services/chat";
import AlertContext from "../../contexts/AlertContext";


export default function GroupChatsList(props) {
	const { mobile, setEvent, reload, setReload } = props;

	const theme = useTheme();
	const { mode } = theme.palette;

	const navigate = useNavigate();

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// auth
	const authContext = useContext(AuthContext);
	const { token } = authContext;

	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState();
	const [selected, setSelected] = useState(null);


	async function getPreviews(events) {
		const _groups = [];
		for (const event of events) {
			const { _id } = event;

			const res = await getFirstMessage(_id, token);
			const { messages } = res.data;

			const temp = {};
			temp["event"] = event;
			temp["recent"] = messages[0];

			_groups.push(temp);
		}
		return _groups;
	}


	useEffect(() => {
		if (reload) {
			setLoading(true);
			getAllEvents({ joined: true }, token)
				.then((events) => {
					if (events.length === 0) {
						setAlert({ open: true, message: "There's nothing here yet! Join an event to chat with others", severity: "info"});
					} else {
						const _selected = selected || 0;
						setSelected(_selected);
						setEvent({
							eventId: events[_selected]._id,
							eventTitle: events[_selected].title,
							event: events[_selected],
						});

						getPreviews(events)
							.then((_groups) => setGroups(_groups));
					}
				})
				.catch((err) => {
					setAlert({ open: true, message: "Error: Something went wrong. Please try again.", severity: "error" });
					console.log(err);
				})
				.finally(() => {
					setLoading(false);
					setReload(false);
				});
		}
		// eslint-disable-next-line
    }, [reload]);


	return (
		<>
			{loading ? 
				<Box sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
					<CircularProgress color="primary" /> 
				</Box>
				:
				<Virtuoso
					style={{ height: "100%" }}
					data={groups}
					itemContent={(index, groupChat) => 
						mobile ? 
							<Box onClick={() => navigate(`/chat/${groupChat.event._id}`)} sx={{ height: "100%" }}>
								<GroupChat event={groupChat.event} recent={groupChat.recent}/>
							</Box>
							:
							<Box 
								sx={{
									backgroundColor: index === selected 
										? 
										(mode === "light" ? "#F3F3F3" : "#282828") 
										: 
										undefined,
								}}
								onClick={() => {
									setSelected(index);
									setEvent({
										eventId: groupChat.event._id,
										eventTitle: groupChat.event.title,
										event: groupChat.event,
									});
								}}
							>
								<GroupChat event={groupChat.event} recent={groupChat.recent}/>
							</Box> 
					}
				/>
			}
		</>
	);
}

GroupChatsList.propTypes = {
	mobile: PropTypes.bool.isRequired,
	setEvent: PropTypes.func.isRequired,
	reload: PropTypes.bool.isRequired,
	setReload: PropTypes.func.isRequired,
};
