import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button, Box, Grid, Typography } from "@mui/material";

// local
import main_png from "../../assets/landing_page/main.png";
import logo_png from "../../assets/landing_page/logo_large.png";
import app_store_svg from "../../assets/landing_page/app_store.svg";


export default function LandingPageContentMain(props) {
	const { hideAppStoreButton, loginPath } = props;

	const navigate = useNavigate();


	return (
		<>
			<Box sx={{ width: "100%" }}>
				<Grid container spacing={2} mt={8} sx={{ px: 5 }}>
					<Grid item xs={12} md={6} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Box zIndex={500} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
							<Box
								component="img"
								sx={{
									maxWidth: "75%",
								}}
								src={logo_png}
								mb={3}
							/>
							<Typography mb={4} sx={{ fontSize: 25, fontWeight: 300, wordWrap: "break-word", lineHeight: 1.2 }}>
								Cost-pooling made easy
							</Typography>
							<Grid container direction="row" spacing={3} sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
								<Grid item>
									<Button variant="contained" color="accent" sx={{ borderRadius: 50, width: 160, height: 50 }} onClick={() => navigate(loginPath)}>
										<Typography color="White" sx={{
											fontSize: 18,
											fontWeight: 600,
											textTransform: "none",
										}}>
											Get Started
										</Typography>
									</Button>
								</Grid>
								{!hideAppStoreButton &&
									<Grid item>
										<a href="https://apps.apple.com/us/app/split-it-cost-pooling/id1637904610" target="_blank" rel="noreferrer">
											<Box component="img" src={app_store_svg}/>
										</a>
									</Grid>
								}
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12} md={6} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Box
							component="img"
							sx={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
							// pt={5}
							src={main_png}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

LandingPageContentMain.propTypes = {
	hideAppStoreButton: PropTypes.bool,
	loginPath: PropTypes.string.isRequired,
};
