import PropTypes from "prop-types";
import { useState, useContext } from "react";
import { Paper, TextField, Button, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Box, Modal } from "@mui/material";

// local
import { reportUser } from "../../services/users";
import AuthContext from "../../contexts/AuthContext";
import AlertContext from "../../contexts/AlertContext";


export default function ReportUserModal(props) {
	const { open, setOpen, username, userID } = props;

	const [reportType, setReportType] = useState("Didn't show up");
	const [reportDescription, setReportDescription] = useState("");

	// hooks

	// auth
	const authContext = useContext(AuthContext);
	const { token } = authContext;

	// alert
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	// functions

	async function submitReport() {
		try {
			await reportUser(token, userID, reportType, reportDescription);

			// sucess
			setAlert({ message: "Submitted Report!", open: true, severity: "success" });

			// reset
			setOpen(false);
			setReportDescription("");
			setReportType("Didn't show up");
		} catch (error) {
			setAlert({ message: "Error: Something went wrong. Please try again.", open: true, severity: "error" });
			console.log(error);
		}
	}


	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			sx={{
				overflowY: "scroll",
				display: "flex",
				alignItems: "safe center",
				justifyContent: "center",
			}}  
		>
			<Box 
				container
				sx={{
					width: 400,
				}}
			>
				<Paper>
					<FormControl >
						<Box ml={3} mt={5} mb={3}>
							<FormLabel sx={{fontSize: 20, fontWeight: "medium"}} id="demo-radio-buttons-group-label">
                  Why are you reporting {username}?
							</FormLabel>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								name="radio-buttons-group"
								defaultValue= "Didn't show up"
								onChange={(e)=>{
									setReportType(e.target.value);
								}}
							>
								<FormControlLabel value="Didn't show up" control={<Radio />} label="Didn't show up" />
								<FormControlLabel value="Didn't pay" control={<Radio />} label="Didn't pay" />
								<FormControlLabel value="Other" control={<Radio />} label="Other" />
							</RadioGroup>
						</Box>
					</FormControl>
          
					<Box ml={3} pb={3} sx={{width: 300, maxWidth: "100%",}}>
						<TextField
							fullWidth
							id="filled-basic"
							label="Notes"
							multiline
							rows={4}
							onChange={(e)=>{
								setReportDescription(e.target.value);
							}}
						/>
					</Box>
					<Box ml={3} pb = {3} sx={{width: 300, maxWidth: "100%"}}>
						<Button variant="outlined" 
							sx= {{":hover": {
								bgcolor: "#508580", 
								color: "white",
								borderColor:"#508580"},
							color:"#FFFFFF", backgroundColor:"#73bfb8", borderColor:"#73bfb8", textTransform:"none", fontWeight:"bold"}} 
							onClick={() => submitReport()}>
                Submit
						</Button>
					</Box>
				</Paper>
			</Box>
		</Modal>
	);
}

ReportUserModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	username: PropTypes.string,
	userID: PropTypes.string,
};
