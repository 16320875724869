import PropTypes from "prop-types";
import { ListItem, Typography } from "@mui/material";

export default function BroadcastMessage(props) {
	const { content } = props;

	return (
		<ListItem>
			<Typography sx={{
				width: "100%",
				textAlign: "center",
			}}>
				{content}
			</Typography>
		</ListItem>
	);
}

BroadcastMessage.propTypes = {
	content: PropTypes.string.isRequired,
};
