import PropTypes from "prop-types";
import { Grid, Typography, ListItem, Avatar } from "@mui/material";


export default function ChatMessage(props) {
	const { message, highlight, letter } = props;
	const { sender, sent_at, content } = message;

	return (
		<ListItem>
			<Grid container direction="row" spacing={1} sx={{
				backgroundColor: highlight ? "#F3F3F3" : "white",
			}}>
				<Grid item sx={{
					display: "flex",
					justifyContent: "center",
				}}>
					<Avatar sx={{
						background: "#47ca63",
						height: 30,
						width: 30,
					}}>
						<Typography sx={{
							fontSize: 14,
							fontFamily: "Muli",
							lineHeight: "30px",  // must be same as height
							fontWeight: "bold",
						}}>
							{letter.toUpperCase()} 
						</Typography>
					</Avatar>
				</Grid>
				<Grid item xs={true}>
					<Grid container direction="column">
						<Grid item>
							<Grid container direction="row" spacing={1}>
								<Grid item>
									<Typography sx={{
										fontFamily: "Muli",
										fontWeight: "bold",
									}}>
										{sender}
									</Typography>
								</Grid>
								<Grid item>
									<Typography sx={{
										fontFamily: "Muli",
									}}>
										{new Date(sent_at).toLocaleString().split(",")[1]}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Typography sx={{
								fontFamily: "Muli",
							}}>
								{content}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ListItem>
	);
}

ChatMessage.propTypes = {
	message: PropTypes.object.isRequired,
	highlight: PropTypes.bool,
	letter: PropTypes.string.isRequired,
};
