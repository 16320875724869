import { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { Avatar, Button, TextField, Grid, Box, Typography, Container } from "@mui/material";


function BackdoorLoginView() {
	const [jhed, setJhed] = useState("");
	const [password, setPassword] = useState("");

	async function handleSubmit(event) {
		event.preventDefault();
		window.open(process.env.REACT_APP_BACKEND_URL + `/backdoor/login/?password=${password}&jhed=${jhed}`, "_self");
	}

	return (
		<Container component="main" maxWidth="xs">
			<Box
				sx={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography sx={{
					fontSize: 36,
				}}>
                    Welcome!
				</Typography>
				<Typography sx={{
					fontSize: 20,
				}}>
                    Backdoor Login (FOR ADMIN ONLY.)
				</Typography>
				<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								required
								sx={{ width: "100%" }}
								label="JHED"
								value={jhed}
								onChange={(event) => setJhed(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								sx={{ width: "100%" }}
								label="Password"
								autoComplete="password"
								type="password"
								value={password}
								onChange={(event) => setPassword(event.target.value)}
							/>
						</Grid>
					</Grid>
					<Button
						variant="contained"
						sx={{ mt: 3, mb: 2, width: "100%" }}
						onClick={handleSubmit}
					>
                        Log in
					</Button>
				</Box>
			</Box>
		</Container>
	);
}

export default BackdoorLoginView;
