import { useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Toolbar } from "@mui/material";

// local
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";


export default function MobileAppBar(props) {
	const { filterState, setFilterState, setFilters } = props;

	// states
	const [openFilter, setOpenFilter] = useState(false);


	return (
		<Box sx={{
			width: "100%",
		}}>
			<AppBar position="sticky" color="transparent" style={{ boxShadow: "0px 0px 0px 0px" }} sx={{
				display: "flex",
				alignContent: "center",
			}}>
				<Toolbar sx={{
					pt: 2,
					pr: 2,
					mb: -2,
					display: "flex",
					justifyContent: "right",
				}}>
					<SwipeableEdgeDrawer
						open={openFilter}
						setOpen={setOpenFilter}
						filterState={filterState}
						setFilterState={setFilterState}
						setFilters={setFilters}
					/>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

MobileAppBar.propTypes = {
	filterState: PropTypes.object.isRequired,
	setFilterState: PropTypes.func.isRequired,
	setFilters: PropTypes.func.isRequired,
};
