import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";

// local
import pie_png from "../../assets/pie.png";


export default function Navbar(props) {
	const { loginPath } = props;

	const navigate = useNavigate();


	return (
		<AppBar elevation={0} color="white" sx={{
			width: "100%",
			height: 100,
			display: "flex",
			justifyContent: "center",
		}}>
			<Toolbar style={{ alignItems: "space-between", justifyContent: "space-between" }}>
				<Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<Button
						sx={{ padding: 0 }}>
						<img
							src={pie_png}
							alt="Split.it Logo"
							style={{
								height: 55,
								width: 55,
							}}
						/>
					</Button>
				</Box>
				<Button
					variant="contained"
					color="accent"
					sx={{
						borderRadius: 50,
						width: 100,
						mr: 3,
					}}
					onClick={() => navigate(loginPath)}
				>
					<Typography color="White" sx={{
						fontSize: 18,
						fontWeight: 600,
						textTransform: "none",
					}}>
                        Login
					</Typography>
				</Button>
			</Toolbar>
		</AppBar>
	);
}

Navbar.propTypes = {
	loginPath: PropTypes.string.isRequired,
};
