import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { IconButton, Box } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// local
import SortBySelect from "./SortBySelect";

// custom sort button styling
const SortButton = styled((props) => {
	const { ...other } = props;
	return <IconButton {...other} />;
})(({ theme, toggle }) => ({
	transform: !toggle ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));


export default function SortEvents(props) {
	const { filterState, setFilterState } = props;

	return (
		<Box sx={{
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "end",
			pr: 2,
		}}>
			<SortBySelect
				value={filterState.sort}
				setValue={(value) => {
					setFilterState(state => ({
						...state,
						sort: value,
					}));
				}}
			/>
			<SortButton
				sx={{ height: 40, width: 40, ml: 1 }}
				toggle={+filterState.descending}
				onClick={() => setFilterState(state => ({
					...state,
					descending: !filterState.descending,
				}))}>
				<ArrowDownwardIcon />
			</SortButton>
		</Box>
	);
}

SortEvents.propTypes = {
	filterState: PropTypes.object.isRequired,
	setFilterState: PropTypes.func.isRequired,
};
