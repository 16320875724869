import axios from "axios";

// if you don"t have a BASE_URL in your config.env,
// then it will try to access a local build.
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

async function verifyToken(token) {
	return axios.post(`${BASE_URL}/verify`, {
		token: token,
	});
}

export { verifyToken };
