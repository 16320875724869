import moment from "moment";
import { useState } from "react";
import { TimePicker } from "antd";
import PropTypes from "prop-types";
import DateRangePicker from "@mui/lab/DateRangePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography, ListItem, Divider, TextField, Button, 
	Checkbox, FormGroup, FormControlLabel } from "@mui/material";

// local
import Filter from "../desktop/Filter";

// styles
import "antd/dist/antd.min.css";

// constants
const TIME_FORMAT = "HH:mm";


export default function FilterItems(props) {
	const { setFilters, toggleDrawer } = props;

	// states
	const [expanded, setExpanded] = useState(false);
  
	const [tempFilters, setTempFilters] = useState({
		timeRange: [null, null],
		sort: "date",
		joined: false,
	});

	// functions
  
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};


	return (
		<>
			<ListItem>
				<Box sx={{ width: "100%" }}>
					<Accordion 
						expanded={expanded === "panel1"} 
						onChange={handleChange("panel1")}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Date
							</Typography>
							<Filter 
								filterText={tempFilters.dateRange && tempFilters.dateRange[0] && tempFilters.dateRange[1] ? 
									tempFilters.dateRange[0].toLocaleDateString("en-US") + " to " + tempFilters.dateRange[1].toLocaleDateString("en-US")
									: ""} 
								clear={() => {
									const state = {...tempFilters};
									delete state["dateRange"];
									setTempFilters(state);
								}}
							/>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ 
								display: "flex", 
								direction: "row", 
								alignItems: "center",
							}}>
								<DateRangePicker
									disablePast
									startText="Start"
									endText="End"
									value={tempFilters.dateRange || [null, null]}
									onChange={(value) => {
										setTempFilters({...tempFilters, dateRange: value});
									}}
									renderInput={(startProps, endProps) => (
										<>
											<TextField {...startProps} />
											<Box sx={{ mx: 2 }}>
												<Typography>
                                                to 
												</Typography>
											</Box>
											<TextField {...endProps} />
										</>
									)}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
					<Accordion 
						expanded={expanded === "panel2"} 
						onChange={handleChange("panel2")}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel2bh-content"
							id="panel2bh-header"
						>
							<Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Time
							</Typography>
							<Filter 
								filterText={tempFilters.timeRange[0] && tempFilters.timeRange[1] ? 
									tempFilters.timeRange[0].toLocaleTimeString([], {hour: "numeric", minute:"2-digit"}) 
                                                    + " to " 
                                                    + tempFilters.timeRange[1].toLocaleTimeString([], {hour: "numeric", minute:"2-digit"})
									: ""} 
								clear={() => {
									setTempFilters(state => ({
										...state, 
										timeRange: [null, null],
									}));
								}}
							/>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{ 
								display: "flex", 
								direction: "row", 
								alignItems: "center",
							}}>
								<TimePicker
									className="timepicker-styles"
									size="large" 
									minuteStep={5} 
									format={TIME_FORMAT} 
									placeholder="Start"
									style={{ width: 243, height: 56, fontSize:20, background: "rgb(0,0,0,0)" }}
									getPopupContainer={triggerNode => triggerNode.parentNode}
									value={tempFilters.timeRange[0] ? moment(tempFilters.timeRange[0]) : undefined}
									onChange={(time) => setTempFilters({
										...tempFilters, 
										timeRange: tempFilters.timeRange.map((v, i) => i === 0 ? time && time.toDate() : v)
									})}
								/>
								<Box sx={{ mx: 2 }}>
									<Typography>
                                        to 
									</Typography>
								</Box>
								<TimePicker
									className="timepicker-styles"
									size="large" 
									minuteStep={5} 
									format={TIME_FORMAT} 
									placeholder="End"
									style={{ width: 243, height: 56, fontSize:20, background: "rgb(0,0,0,0)" }}
									getPopupContainer={triggerNode => triggerNode.parentNode}
									value={tempFilters.timeRange[1] ? moment(tempFilters.timeRange[1]) : undefined}
									onChange={(time) => {setTempFilters({
										...tempFilters, 
										timeRange: tempFilters.timeRange.map((v, i) => i === 1 ? time && time.toDate() : v)
									});}}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel3bh-content"
							id="panel3bh-header"
						>
							<Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Destination
							</Typography>
							<Filter 
								filterText={tempFilters["destination"] || ""} 
								clear={() => {
									const state = {...tempFilters};
									delete state["destination"];
									setTempFilters(state);
								}}
							/>
						</AccordionSummary>
						<AccordionDetails>
							<TextField 
								variant="standard"
								size="large"
								value={tempFilters["destination"] || ""}
								onChange={(event) => {setTempFilters({...tempFilters, destination: event.target.value});}}
								sx={{
									width: "100%",
								}}
							/>
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel4bh-content"
							id="panel4bh-header"
						>
							<Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Pickup Location
							</Typography>
							<Filter 
								filterText={tempFilters["departure"] || ""} 
								clear={() => {
									const state = {...tempFilters};
									delete state["departure"];
									setTempFilters(state);
								}}
							/>
						</AccordionSummary>
						<AccordionDetails>
							<TextField 
								variant="standard"
								size="large"
								value={tempFilters["departure"] || ""}
								onChange={(event) => {setTempFilters({...tempFilters, departure: event.target.value});}}
								sx={{
									width: "100%",
								}}
							/>
						</AccordionDetails>
					</Accordion>
				</Box>
			</ListItem>
			<Divider/>
			<ListItem sx={{ ml: 1 }}>
				<FormGroup>
					<FormControlLabel 
						label="My Events"
						control={
							<Checkbox
								checked={tempFilters.joined}
								onChange={(event) => setTempFilters(state => ({
									...state,
									joined: event.target.checked
								}))}
							/>
						}
					/>
				</FormGroup>
			</ListItem>
			<Divider/>
			<ListItem sx={{ position: "relative" }}>
				<Button 
					onClick={() => {
						setFilters(tempFilters);
						if (toggleDrawer) {
							toggleDrawer(false);
						}
					}}
					sx={{
						position: "absolute",
						top: 10,
						right: 5,
					}}
				>
                    Apply
				</Button>
			</ListItem>
		</>
	);
}

FilterItems.propTypes = {
	setFilters: PropTypes.func.isRequired,
	toggleDrawer: PropTypes.func,
};
