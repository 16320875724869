import PropTypes from "prop-types";
import { useState, useContext, useEffect } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Typography, Menu, MenuItem } from "@mui/material";

// local
import { getUser } from "../../services/users";
import AuthContext from "../../contexts/AuthContext";
import ReportUserModal from "../desktop/ReportUserModal";


export default function PassengerList(props) {
	const { names, passengers } = props;

	// fields
	const options = [
		"Report",
	];

	// states

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const [username, setUsername] = useState("");
	const [reportedUserID, setReportedUserID] = useState(null);
	const [reportedUserUsername, setReportedUserUsername] = useState(null);
	const [reportUserModalState, setReportUserModalState] = useState(false);

	// hooks

	const authContext = useContext(AuthContext);
	const { token } = authContext;

	// effects

	useEffect(() => {
		getUser(token)
			.then((res) => {
				const _user = res.data.data;
				setUsername(_user.username);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [token]);

	// functions

	const handleClick = (event, name, names, passengers) => {
		setAnchorEl(event.currentTarget);
		setReportedUserUsername(name);
		setReportedUserID(passengers[names.indexOf(name)]);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleReport = () => {
		setReportUserModalState(!reportUserModalState);
	};


	return (
		<>
			<Typography
				variant="body2"
				color="text.secondary"
				sx={{ listStylePosition: "inside" }}
			>
				<b>Passengers:</b>
				{names.map((name, i) => (
					<li key={name} display='block' component="div">
						{name} {i === 0 && "(Host)"}
						{(name !== username) &&
							<>
								<IconButton
									sx={{
										p: "0.2px",
										ml: 1,
									}}
									onClick={(event) => handleClick(event, name, names, passengers)}
								>
									<MoreHorizIcon />
								</IconButton>
								<Menu
									anchorEl={anchorEl}
									anchorOrigin={{ vertical: "top", horizontal: "right" }}
									open={open}
									onClose={handleClose}
									PaperProps={{
										style: {
											maxHeight: 48 * 4.5,
											width: "8ch",
										}
									}}
								>
									{options.map((option) => (
										<MenuItem
											sx={{
												color: "red",
												fontSize: 12,
												fontWeight: "medium"
											}}
											key={option}
											selected={option === "Pyxis"}
											onClick={handleReport}
										>
											{option}
										</MenuItem>))
									}
								</Menu>
								<ReportUserModal
									open={reportUserModalState}
									setOpen={(open) => setReportUserModalState(open)}
									userID={reportedUserID}
									username={reportedUserUsername}
								>
								</ReportUserModal>
							</>
						}
					</li>
				))}
			</Typography>
		</>
	);
}

PassengerList.propTypes = {
	passengers: PropTypes.arrayOf(PropTypes.string).isRequired,
	names: PropTypes.arrayOf(PropTypes.string).isRequired,
};
