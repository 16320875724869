import Twitter from "@mui/icons-material/Twitter";
import Facebook from "@mui/icons-material/Facebook";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Instagram from "@mui/icons-material/Instagram";
import { Link, Box, Typography, Button, IconButton } from "@mui/material";


export default function Footer() {
	return (
		<>
			<Box sx={{ height: 300, backgroundColor: "#EC7518" }} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
				<Box height="50%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ px: 5, gap: 5 }}>
					<Typography variant="h4" color="#f5f5f5" sx={{ fontWeight: "bold", fontFamily: "Montserrat", textAlign: "center" }}>
            Want to use Split.it at your university?
					</Typography>
					<Button variant="contained" color="white" sx={{ borderRadius: 50, width: 200, height: 50 }} href="mailto: trysplitit@gmail.com">
						<Typography color="#EC7518" sx={{
							fontSize: 18,
							fontWeight: 600,
							textTransform: "none",
						}}>
              Send us an email
						</Typography>
					</Button>
				</Box>

			</Box>

			<Box sx={{ height: 100, mx: 3 }} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
				<Box sx={{ height: 100, mx: 3 }} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
					<Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 2 }}>
						<Typography sx={{ fontFamily: "Montserrat", fontColor: "black", fontSize: 18, color: "#000" }}>
              FOLLOW US
						</Typography>
						<Box>
							<IconButton href="https://www.facebook.com/trysplitit/" sx={{ color: "black" }}>
								<Facebook />
							</IconButton>
							<IconButton href="https://www.instagram.com/trysplitit/" sx={{ color: "black" }}>
								<Instagram />
							</IconButton >
							<IconButton href="https://twitter.com/trysplitit" sx={{ color: "black" }}>
								<Twitter />
							</IconButton >
							<IconButton href="https://www.linkedin.com/company/try-split-it/" sx={{ color: "black" }}>
								<LinkedIn />
							</IconButton>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Link 
						sx={{ mt: 0.3, mr: 5, fontSize: 15 }} 
						underline="none"
						href="/privacy-policy.html"
						target="_blank"
					> 
            Privacy Policy 
					</Link>
					{/* <Link sx={{ mt: 0.3, mr: 5, fontSize: 15 }} underline="none"> Subscribe </Link>
          <Link sx={{ mt: 0.3, mr: 5, fontSize: 15 }} underline="none"> Team </Link>
          <Link sx={{ mt: 0.3, mr: 5, fontSize: 15 }} underline="none"> Alumni </Link>
          <Link sx={{ mt: 0.3, mr: 5, fontSize: 15 }} underline="none"> About </Link> */}
				</Box>
			</Box>
		</>
	);
}

Footer.propTypes = {};
