import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import AccountIcon from "@mui/icons-material/AccountCircle";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";

// local
import AuthContext from "../../contexts/AuthContext";


export default function MobileAppBarBottom() {
	// hooks

	const navigate = useNavigate();

	// get user object
	const authContext = useContext(AuthContext);
	const { user } = authContext;
  

	return (
		<AppBar position="fixed" color="white" sx={{ top: "auto", bottom: 0, height: 70}}>
			<Toolbar sx={{justifyContent: "space-between", pt: 1}}>
				<IconButton onClick={() => navigate("/card")}
					color="inherit" aria-label="open drawer">
					<ViewAgendaOutlinedIcon fontSize="large"/>
				</IconButton>
				<IconButton onClick={() => navigate("/calendar")}
					color="inherit" aria-label="open drawer">
					<CalendarIcon fontSize="large"/>
				</IconButton>
				<IconButton onClick={() => navigate(`/joined/${user._id}`)}
					color="inherit">
					<FavoriteIcon fontSize="large"/>
				</IconButton>
				<IconButton onClick={() => navigate("/chat")}
					color="inherit">
					<ChatBubbleOutline fontSize="large"/>
				</IconButton> 
				<IconButton onClick={()  => navigate("/account")}
					color="inherit">
					<AccountIcon fontSize="large"/>
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
