import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useContext, useEffect } from "react";

// local
import logo_png from "../assets/logo.png";
import jhu_svg from "../assets/JHU_logo.svg";
import { verifyToken } from "../services/auth";
import AuthContext from "../contexts/AuthContext";
import BackButton from "../components/reusable/BackButton";

// env
let DEMO = process.env.REACT_APP_DEMO;
const DEMO_JHED = process.env.REACT_APP_DEMO_JHED;
const DEMO_PASSWORD = process.env.REACT_APP_DEMO_PASSWORD;
const DEMO_URL = process.env.REACT_APP_DEMO_URL;

if (DEMO) {
	DEMO = (DEMO === "true");
}

// global
let isMounted = true;


export default function LoginView(props) {
	const { backButtonPath } = props;

	const [loading, setLoading] = useState(false);

	// hooks
	const navigate = useNavigate();
	// auth
	const authContext = useContext(AuthContext);
	const { token, user } = authContext;

	// effects

	useEffect(() => {
		if (backButtonPath === "/ios") {
			localStorage.setItem("loginPath", "/login-ios");
		}
		// set isMounted to false on dismount
		return () => { isMounted = false; };
	}, []); 

	function handleLogin() {
		if (token) {
			setLoading(true);
			verifyToken(token)
				.then(res => {
					if (!!res.data.token && user && user._id) {
						navigate("/card");
					} else {
						window.open(process.env.REACT_APP_SSO_URL + "/jhu/login", "_self");
					}
				})
				.catch(() => {
					window.open(process.env.REACT_APP_SSO_URL + "/jhu/login", "_self");
				})
				.finally(() => {
					if (isMounted) setLoading(false);
				});
		} else {
			window.open(process.env.REACT_APP_SSO_URL + "/jhu/login", "_self");
		}
	}


	return (
		<>
			<BackButton path={backButtonPath}/>
			<Box
				sx={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}
			>
				<Grid container direction="column" spacing={1} sx={{
					alignItems: "center",
				}}>
					<Grid item>
						<Box component="img" src={logo_png} width={1592 / 4} height={698 / 4}/>
					</Grid>
					{!DEMO && 
						<Grid item>
							<Box component="img" src={jhu_svg} width={223} height={38} />
						</Grid>
					}
					<Grid item>
						{DEMO ? 
							<Button
								type="submit"
								variant="contained"
								sx={{ mt: 3, mb: 2, width: "100%" }}
								onClick={() => 
								// authenticate with demo user
									window.open(process.env.REACT_APP_BACKEND_URL + `/backdoor/login?password=${DEMO_PASSWORD}&jhed=${DEMO_JHED}`, "_self")
								}
							>
                                Get Started
							</Button>
							:
							<>
								<LoadingButton
									loading={loading}
									type="submit"
									variant="contained"
									sx={{ mt: 3, mb: 2, width: "100%" }}
									onClick={handleLogin}
								>
                                    Log In With SSO
								</LoadingButton>
								<LoadingButton
									loading={loading}
									type="submit"
									variant="contained"
									color="accent"
									sx={{ mb: 2, width: "100%" }}
									onClick={() => {
										setLoading(true);
										window.open(DEMO_URL, "_self");
									}}
								>
                                    Demo
								</LoadingButton>
							</>
						}
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

LoginView.propTypes = {
	backButtonPath: PropTypes.string.isRequired,
};
