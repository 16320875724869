/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Animate } from "react-move";
import { easeQuadInOut } from "d3-ease";
import ManIcon from "@mui/icons-material/Man";
import { Box, Typography, Grid } from "@mui/material";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

// styles
import "react-circular-progressbar/dist/styles.css";


const style = {
	strokeLinecap: "butt",
	pathTransition: "none",
	pathColor: "#73BFB8",
	textColor: "#73BFB8",
	textSize: "20px"
};


class AnimatedProgressProvider extends React.Component {
	interval = undefined;

	state = {
		isAnimated: false
	};

	static defaultProps = {
		valueStart: 0
	};

	componentDidMount() {
		if (this.props.repeat) {
			this.interval = window.setInterval(() => {
				this.setState({
					isAnimated: !this.state.isAnimated
				});
			}, this.propTypes.duration * 1000);
		} else {
			this.setState({
				isAnimated: !this.state.isAnimated
			});
		}
	}

	render() {
		return (
			<Animate
				start={() => ({
					value: this.props.valueStart
				})}
				update={() => ({
					value: [
						this.state.isAnimated ? this.props.valueEnd : this.props.valueStart
					],
					timing: {
						duration: this.props.duration * 1000,
						ease: this.props.easingFunction
					}
				})}
			>
				{({ value }) => this.props.children(value)}
			</Animate>
		);
	}
}


export default function SpotsLeft(props) {
	const { numPassengers, numTotal } = props;

	const valueEnd = (numPassengers * 1.0) / numTotal * 100;

	return (
		<AnimatedProgressProvider
			valueStart={0}
			valueEnd={valueEnd}
			duration={1}
			easingFunction={easeQuadInOut}
		>
			{value => {
				const roundedValue = Math.round(value);
				return (
					<CircularProgressbarWithChildren 
						styles={buildStyles(style)}
						value={roundedValue} 
						font={""}
						strokeWidth={10}
					>
						<Grid container direction="column" sx={{ width: 50, height: 50 }}>
							<Grid item sx={{ display: "flex", justifyContent: "center", width: "100%" }} xs={3}>
								<Typography sx={{ 
									fontFamily: "Gudea", 
									fontSize: 15,
									letterSpacing: -2, 
								}}>
									{numPassengers} / {numTotal}
								</Typography>
							</Grid>
							<Grid item sx={{ display: "flex", justifyContent: "center", width: "100%" }} xs={3}>
								<Grid container direction="row">
									{Array(numTotal).fill().map((_, i) => (
										<Grid item key={i} xs={true} sx={{ 
											display: "flex", 
											justifyContent: "center",
										}}>
											<Box sx={{
												width: 45 / numTotal,
												height: 45 / numTotal,
												display: "flex",
												justifyContent: "center",
											}}>
												<ManIcon sx={{
													transform: `scale(${4/3 - 1/9 * numTotal})`,
													color: i < numPassengers ? "#5b5b5b" : "#bcbcbc"
												}}/>        
											</Box>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					</CircularProgressbarWithChildren>
				);
			}}
		</AnimatedProgressProvider>
	);
}

SpotsLeft.propTypes = {
	numPassengers: PropTypes.number.isRequired,
	numTotal: PropTypes.number.isRequired,
};
